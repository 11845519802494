import { FetchResult } from "@apollo/client";
import { useEffect, useState } from "react";

import { getOffsetDateAsISOString } from "../../../utils/datetime";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  FreemiumScheduledInterviewListItemFragment,
  GreenhouseSyncStatus,
  UpdateMyInterviewRecordingOptionsMutation,
  useCurrentUserFreemiumScheduledInterviewsQuery,
  useCurrentUserInterviewImportRuleQuery,
  useGreenhouseFreemiumSyncStatusQuery,
  UserInterviewImportRule,
  useUpdateMyInterviewRecordingOptionsMutation,
} from "..";

const PAGE_SIZE = 15;

export type RecordMyInterviewsReturn = {
  /** The current user's selected "record my interviews" rule */
  importRule: UserInterviewImportRule | null;
  importRuleLoading: boolean;
  scheduledInterviews: FreemiumScheduledInterviewListItemFragment[] | null;
  scheduledInterviewsLoading: boolean;
  /** Triggers a delayed re-fetch of `scheduledInterviews` */
  updateRule(
    rule: UserInterviewImportRule | null
  ): Promise<FetchResult<UpdateMyInterviewRecordingOptionsMutation>>;
  updateRuleLoading: boolean;
  syncStatus?: GreenhouseSyncStatus;
  refetchStatusAndInterviews: () => void;
};

/**
 * Queries and mutations for the current user's "record my interview" rule.
 *
 * The mutation to update this rule needs time to propagate before the resulting
 * interviews are re-fetched, so a mock load time is included in the logic
 * of this hook
 */
export const useRecordMyInterviews = (): RecordMyInterviewsReturn => {
  const { data: importRuleData, loading: importRuleLoading } =
    useCurrentUserInterviewImportRuleQuery();
  const importRule = importRuleData?.currentUser?.interviewImportRule ?? null;

  const [startDate] = useState(getOffsetDateAsISOString({ hours: -1 }));
  const [endDate] = useState(getOffsetDateAsISOString({ days: 7 }));

  const {
    data: scheduledInterviewsData,
    loading: scheduledInterviewsLoading,
    refetch: refetchScheduledInterviews,
  } = useCurrentUserFreemiumScheduledInterviewsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      start: startDate,
      end: endDate,
      pagination: { limit: PAGE_SIZE },
    },
  });

  const currentUser = useCurrentUser();
  const [updateRule, { loading: updateRuleLoading }] =
    useUpdateMyInterviewRecordingOptionsMutation({
      onCompleted: () => {
        setMockLoading(true);
      },
      optimisticResponse: ({ importRule: interviewImportRule }) => ({
        updateMyInterviewRecordingOptions: {
          __typename: "UpdateMyInterviewRecordingOptions",
          currentUser: {
            __typename: "User",
            id: currentUser.id,
            interviewImportRule,
          },
        },
      }),
    });

  const [syncStatus, setSyncStatus] = useState<
    GreenhouseSyncStatus | undefined
  >();
  const {
    loading: syncStatusLoading,
    stopPolling,
    refetch: refetchStatus,
  } = useGreenhouseFreemiumSyncStatusQuery({
    onCompleted: (data) => {
      if (data.greenhouseFreemiumSyncStatus) {
        setSyncStatus(data.greenhouseFreemiumSyncStatus.status || undefined);
      }
    },
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (syncStatus === GreenhouseSyncStatus.Finished) {
      refetchScheduledInterviews();
      stopPolling();
    }
    if (syncStatus === GreenhouseSyncStatus.Failed) {
      stopPolling();
    }
  }, [syncStatus]);

  /**
   * Wait 5s before re-fetching interviews to allow them to process
   */
  const [mockLoading, setMockLoading] = useState(false);
  useEffect(() => {
    if (mockLoading) {
      const timeout = setTimeout(() => {
        refetchScheduledInterviews();
        setMockLoading(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [mockLoading]);

  const refetchStatusAndInterviews = (): void => {
    refetchStatus().then(() => refetchScheduledInterviews());
  };

  return {
    importRule,
    importRuleLoading,
    scheduledInterviews:
      scheduledInterviewsData?.currentUser?.scheduledInterviews.results ?? null,
    scheduledInterviewsLoading:
      updateRuleLoading ||
      mockLoading ||
      scheduledInterviewsLoading ||
      syncStatusLoading,
    updateRule: (rule) => updateRule({ variables: { importRule: rule } }),
    updateRuleLoading,
    syncStatus,
    refetchStatusAndInterviews,
  };
};
