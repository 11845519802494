import { Box, Flex, Link } from "@chakra-ui/react";
import React from "react";

import { EmptyVideoPlayer, HandBookIcon } from "../../../../components";

type BaseEmptyStateProps = {
  heading: React.ReactNode;
  description: React.ReactNode;
  icon: React.ReactNode;
};

const BaseEmptyState: React.FC<BaseEmptyStateProps> = ({
  heading,
  description,
  icon,
}) => (
  <Box p={4} border="1px solid" borderColor="gray.100" borderRadius="lg">
    <Flex
      align="center"
      justify="center"
      direction={{ base: "column", lg: "row" }}
      gap="4"
      py="6"
      bg="blackAlpha.50"
      borderRadius="md"
    >
      {icon}

      <Box textAlign={{ base: "center", lg: "start" }}>
        <Box
          color="gray.700"
          fontSize="lg"
          fontWeight="semibold"
          lineHeight="6"
          mb="1"
        >
          {heading}
        </Box>
        <Box color="gray.600" fontSize="md" fontWeight="normal" lineHeight="5">
          {description}
        </Box>
      </Box>
    </Flex>
  </Box>
);

/** Shown when no upcoming interviews */
export const UpcomingEmptyState: React.FC = () => (
  <BaseEmptyState
    icon={<HandBookIcon h="16" colorScheme="gray" />}
    heading="No upcoming interviews yet"
    description={
      <Link
        color="blue.600"
        fontSize="md"
        fontWeight="normal"
        lineHeight="5"
        href="https://help.brighthire.ai/en/articles/6170599-add-brighthire-to-any-interview"
        target="_blank"
      >
        Learn how to schedule an interview in this article.
      </Link>
    }
  />
);

/** Shown when error fetching upcoming interviews */
export const UpcomingErrorState: React.FC = () => (
  <BaseEmptyState
    icon={<HandBookIcon h="16" colorScheme="gray" />}
    heading="Something went wrong fetching your upcoming interviews"
    description="Please try and refresh the page"
  />
);

/** Shown when no completed interviews */
export const CompletedEmptyState: React.FC = () => (
  <BaseEmptyState
    icon={<EmptyVideoPlayer />}
    heading="No completed interviews yet"
    description={
      <Link
        color="blue.600"
        fontSize="md"
        fontWeight="normal"
        lineHeight="5"
        href="https://help.brighthire.ai/en/articles/4534309-reviewing-a-brighthire-interview"
        target="_blank"
      >
        Get a preview of the interview page in this article
      </Link>
    }
  />
);

/** Shown when error fetching completed interviews */
export const CompletedErrorState: React.FC = () => (
  <BaseEmptyState
    icon={<EmptyVideoPlayer />}
    heading="Something went wrong fetching your completed interviews"
    description="Please try and refresh the page"
  />
);
