import { Button } from "@chakra-ui/react";
import React from "react";
import { FaMicrosoft } from "react-icons/fa";

import useOAuth, { OnAuth, OnError } from "../../hooks/useOAuth";

interface MsftAuthButtonProps {
  label: string;
  onAuth: OnAuth;
  onError: OnError;
  feature: "auth" | "calendar";
}

const MsftAuthButton: React.FC<MsftAuthButtonProps> = ({
  label,
  onAuth,
  onError,
  feature = "auth",
}) => {
  const { onClick, isLoading } = useOAuth({
    provider: "msft",
    onAuth,
    onError,
    feature,
  });

  return (
    <Button
      leftIcon={<FaMicrosoft />}
      width="100%"
      onClick={onClick}
      isLoading={isLoading}
      data-testid={isLoading ? null : "msft-auth-button"}
    >
      {label}
    </Button>
  );
};

export default MsftAuthButton;
