import {
  Circle,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

import { TdWhite, ThGray } from "../AnalyticsReportTable";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 4,
  minW: "100px",
  textAlign: "center",
};

type CandidateSkillCoverage = {
  candidateName: string;
  numInterviews: number;
  skills: {
    name: string;
    covered: boolean;
  }[];
};

type AnalyticsSkillsReportTableProps = {
  tableData: CandidateSkillCoverage[];
  skills: string[];
};

const AnalyticsSkillsReportTable: React.FC<AnalyticsSkillsReportTableProps> = ({
  tableData,
  skills,
}) => {
  const average = (arr: number[]): number | null => {
    if (arr.length === 0) {
      return null;
    }
    return arr.reduce((a, b) => a + b, 0) / arr.length;
  };

  const averageNumInterviews = (
    tableData: CandidateSkillCoverage[]
  ): string | undefined => {
    const numInterviews = tableData.map((row) => row.numInterviews);
    return average(numInterviews)?.toFixed(2);
  };

  const percentCoverages: Record<string, string> = {};
  skills.forEach((skill) => {
    const covered = tableData.filter((row) =>
      row.skills.find((s) => s.name === skill && s.covered)
    ).length;
    percentCoverages[skill] = (covered / tableData.length).toFixed(2);
  });

  return (
    <TableContainer>
      <Table variant="simple" whiteSpace="normal">
        <Thead>
          <Tr>
            <ThWrapper textAlign="left">Candidate</ThWrapper>
            <ThWrapper>Interviews</ThWrapper>
            {skills.map((skill) => (
              /* TODO: Better key */
              <ThWrapper key={skill}>{skill}</ThWrapper>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <TdWrapper textAlign="left">Average</TdWrapper>
            <TdWrapper>{averageNumInterviews(tableData)}</TdWrapper>
            {skills.map((skill) => (
              <TdWrapper key={skill}>{`${percentCoverages[skill]}%`}</TdWrapper>
            ))}
          </Tr>
          {tableData.map((row) => (
            /* TODO: Better key */
            <Tr key={row.candidateName}>
              <TdWrapper textAlign="left">{row.candidateName}</TdWrapper>
              <TdWrapper>{row.numInterviews}</TdWrapper>
              {/* TODO: Better key */}
              {row.skills.map((skill, j) => (
                /* TODO: Better key */
                <TdWrapper key={skill.name}>
                  <Dot complete={skill.covered} />
                </TdWrapper>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ThWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <ThGray {...TABLE_CELL_PROPS} {...props}>
    {children}
  </ThGray>
);

const TdWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <TdWhite {...TABLE_CELL_PROPS} {...props} borderColor="inherit">
    {children}
  </TdWhite>
);

const Dot: React.FC<{ complete: boolean }> = ({ complete }) => (
  <Circle
    size="14px"
    backgroundColor={complete ? "green.500" : "gray.100"}
    borderRadius="50%"
    display="inline-block"
  />
);

export default AnalyticsSkillsReportTable;
