import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactSelect, { MultiValue } from "react-select";

import useDebounce from "../../../hooks/useDebounce";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useInterviewersLazyQuery } from "../../graphql";

type StaticSelectProps = {
  stageIds?: string[];
  interviewerIds?: string[];
  disabled?: boolean;
  onSelect: (stage: any) => void;
  size?: "sm" | "md";
  isClearable?: boolean;
  staticList: "stage" | "interviewer";
};

const StaticSelect: React.FC<StaticSelectProps> = ({
  staticList,
}): JSX.Element => {
  const toast = useToast();
  const [theme, styles] = useSelectTheme();
  const [currentValues, setCurrentValues] = useState<MultiValue<unknown>>();
  const [interviewersQuery, { data }] = useInterviewersLazyQuery({
    onError: () => {
      toast({
        title: "Error loading interviewers",
        status: "error",
        isClosable: true,
        position: "top",
      });
    },
  });
  const [query] = useState("");
  const debouncedQuery = useDebounce<string>(query, 200);
  useEffect(() => {
    interviewersQuery({ variables: { query: debouncedQuery } });
  }, [debouncedQuery]);

  const interviewers = data?.currentUser?.organization.users;
  const interviewerIds = interviewers?.map((interviewer) => ({
    label: interviewer.fullName,
  }));

  const items: { [index: string]: any } = {
    stage: [
      { label: "Recruiter Screen" },
      { label: "Hiring Manager Interview" },
      { label: "Interview 1" },
      { label: "Interview 2" },
      { label: "Interview 3" },
      { label: "Interview 4" },
      { label: "Final Interview" },
    ],
    interviewer: interviewerIds ?? [
      { label: "John Doe" },
      { label: "Jane Doe" },
      { label: "John Smith" },
      { label: "Jane Smith" },
    ],
  };
  return (
    <ReactSelect
      id="stage"
      theme={theme}
      styles={styles}
      value={currentValues}
      isMulti
      options={items[staticList].map((val: any) => ({
        label: val.label,
        value: val.label,
      }))}
      onChange={(selectedOption) => {
        setCurrentValues(selectedOption);
      }}
    />
  );
};

export default StaticSelect;
