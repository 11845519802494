import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import config from "../config";
import { track } from "./analytics";
import { checkCookieExistence } from "./cookie";

interface CurrentUser {
  id: string;
  email: string;
  phoneNumber?: string | null;
  fullName: string;
  createdAt: string;
}

let initialized = false;

export const initLogrocket = (): void => {
  document.addEventListener(
    "mousemove",
    (): void => {
      const isImpersonating = checkCookieExistence("is_impersonating");
      if (config.appEnv === "production" && !initialized && !isImpersonating) {
        initialized = true;
        LogRocket.init("otjbrk/brighthire-production", {
          release: config.appVersion,
          dom: {
            inputSanitizer: true,
          },
        });
        setupLogRocketReact(LogRocket);
        LogRocket.getSessionURL((sessionURL) => {
          // Sentry
          Sentry.configureScope((scope) => {
            scope.setExtra("sessionURL", sessionURL);
          });
          // Segment analytics
          track("LogRocket", {
            sessionURL,
          });
        });
      }
    },
    { once: true }
  );
};

export const identifyLogrocket = (currentUser?: CurrentUser): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (
    config.appEnv === "production" &&
    currentUser &&
    initialized &&
    !isImpersonating
  ) {
    LogRocket.identify(currentUser.id, {
      email: currentUser.email,
      phone: currentUser.phoneNumber ?? "",
      fullName: currentUser.fullName,
      createdAt: currentUser.createdAt,
    });
  }
};
