import { Box } from "@chakra-ui/react";
import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";
// eslint-disable-next-line import/no-unresolved
import { Props } from "recharts/types/component/Label";

import colors from "../../../../theme/colors";
import { getFillColor, getFillColorMulticolor } from "./utils";

const CustomTooltip = () => {
  return (
    props: TooltipProps<ValueType, NameType>
  ): React.ReactElement | null => {
    if (props.active) {
      const label = props.payload?.[0].payload.label;
      const value = props.payload?.[0].payload.value;
      const displayValue = !value ? "0%" : `${value.toFixed(1)}%`;
      return (
        <Box
          bgColor="white"
          borderRadius={4}
          border={`1px solid ${colors.gray[200]}`}
          p={3}
          maxW="450px"
        >
          <Box fontWeight={400} fontSize="sm" color={colors.gray[900]}>
            {label}
          </Box>
          <Box fontWeight={400} fontSize="xs" mt={2} color={colors.gray[700]}>
            Discussed by {displayValue} of candidates
          </Box>
        </Box>
      );
    }

    return null;
  };
};

const renderLabel = (props: Props): React.ReactElement | null => {
  const { x, y, value } = props;

  if (!x || !y || !value) return null;
  if (typeof x === "string") return null;
  if (typeof y === "string") return null;
  if (typeof value !== "string") return null;

  return (
    <g>
      <text
        x={x - 10}
        y={y + 9}
        fill="#393F47"
        textAnchor="end"
        dominantBaseline="bottom"
        fontSize="12"
      >
        {value.slice(0, 38)}
        {value.length > 40 ? "..." : null}
      </text>
    </g>
  );
};

export type AnalyticsBarChartData = {
  key: string;
  label: string;
  value: number;
}[];

type AnalyticsBarChartProps = {
  data: AnalyticsBarChartData;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  multiColor?: boolean;
};

const AnalyticsBarChart: React.FC<AnalyticsBarChartProps> = ({
  data,
  margin,
  multiColor,
}) => {
  const motivationTopicCount = data ? Object.keys(data).length : 10;
  const chartHeight = motivationTopicCount * 25;
  const dataMax = Math.max(...data.map((entry) => entry.value));
  return (
    <ResponsiveContainer height={chartHeight}>
      <BarChart
        data={data}
        height={chartHeight}
        layout="vertical"
        barGap={1}
        barCategoryGap={0}
        barSize={10}
        margin={{
          top: 0,
          right: 0,
          left: 200,
          bottom: 0,
          ...margin,
        }}
      >
        <XAxis
          type="number"
          domain={[0, "dataMax"]}
          tickLine={false}
          tickFormatter={(v) => `${Math.round(v)}%`}
          axisLine={{ stroke: "#D3D8DE" }}
          tick={{ fill: "#393F47", textAnchor: "start" }}
          fontSize={12}
          padding={{ right: 50, left: 4 }}
        />
        <YAxis type="category" tick={false} stroke="#D3D8DE" />
        <Tooltip
          content={CustomTooltip()}
          isAnimationActive={false}
          cursor={{ fill: colors.gray[50] }}
        />
        <Bar dataKey="value">
          <LabelList
            dataKey="label"
            position="left"
            content={renderLabel}
            fontSize={12}
          />
          <LabelList
            dataKey="value"
            formatter={(v: number) => (!v ? "0%" : `${v.toFixed(1)}%`)}
            position="right"
            fill="#67717E"
            textAnchor="middle"
            fontSize={12}
            fontWeight="500"
          />
          {data?.map((entry: any, index: number) => (
            <Cell
              fill={
                multiColor
                  ? getFillColorMulticolor(entry.value)
                  : getFillColor(entry.value, dataMax)
              }
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${index}`}
              radius={10}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AnalyticsBarChart;
