import { Box, Button, Flex, Heading, StyleProps } from "@chakra-ui/react";
import React from "react";

import { UpgradeRequestImage } from "../Images";

type UpgradePageProps = StyleProps & {
  header: React.ReactNode;
  body: React.ReactNode;
  buttonText: string;
  splash: React.ReactNode;
  requestSubmitted: boolean;
  onClick(): void;
};

/** UI component for upgrade pages */
export const UpgradePage: React.FC<UpgradePageProps> = ({
  header,
  body,
  buttonText,
  splash,
  requestSubmitted,
  onClick,
  ...styles
}) => {
  return (
    <Flex
      align="center"
      justify="space-around"
      color="gray.800"
      bg="linear-gradient(0deg, #E9F5FF 0%, #E9F5FF 100%), linear-gradient(180deg, rgba(113, 165, 214, 0.60) 100.29%, rgba(173, 223, 244, 0.60) 200.29%);"
      {...styles}
    >
      {requestSubmitted ? (
        <Box textAlign="center" mb="5%">
          <UpgradeRequestImage w={450} h="auto" />
          <Heading as="h3" size="xl" fontWeight="semibold" mb={3} mt={8}>
            Thank you for your interest.
          </Heading>
          <Box fontSize="lg">We&apos;ll be in touch with you shortly.</Box>
        </Box>
      ) : (
        <Flex wrap="wrap-reverse" gap="6" align="center" justify="center">
          <Flex
            direction="column"
            maxW={540}
            minW={400}
            flex="1"
            align={{ base: "stretch", lg: "start" }}
          >
            <Heading as="h3" size="xl" fontWeight="semibold">
              {header}
            </Heading>
            <Box mt={3} fontSize="xl">
              {body}
            </Box>
            <Button onClick={onClick} minW="64" px="8" mt={8} size="lg">
              {buttonText}
            </Button>
          </Flex>
          <Box position="relative" maxW={540} minW={448} flex="1">
            {splash}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
