import {
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  RouterLink,
  Table,
} from "../../../components";
import { formatDatetime, formatRelativeDate } from "../../../utils/datetime";
import {
  AddUserToClientMembershipButton,
  ClientPositionRoleSelect,
  RemoveUserFromHiringTeamGroupButton,
} from "../../components";
import {
  ClientMembership,
  Position,
  useOrgHiringTeamGroupQuery,
} from "../../graphql";
import useLink from "../../hooks/useLink";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";

const HiringTeamGroupInfoPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const clientId = params.id;
  const { data, loading, error } = useOrgHiringTeamGroupQuery({
    variables: {
      id: clientId,
    },
  });

  if (loading) {
    return <LoadingIndicator mt="25%" />;
  }

  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      return <Navigate to="/forbidden" replace />;
    }
    // eslint-disable-next-line no-console
    console.error(error);
    return (
      <Alert status="error" description="Error loading candidate" reload />
    );
  }

  const client = data?.client;
  if (!client) return <Navigate to="/not-found" replace />;

  return (
    <SettingsPageContainer
      maxW="100%"
      heading={
        <Flex direction="row" alignItems="center" pb={4}>
          <IconButton
            size="sm"
            aria-label="Back to hiring team groups"
            icon={<HiArrowLeft size="20" />}
            variant="icon"
            onClick={() => navigate(`/settings/users/hiring/team_groups`)}
          />
          <>{client?.name}</>
        </Flex>
      }
    >
      <Box>
        <Flex>
          <Heading size="sm" pb={4}>
            Details
          </Heading>
        </Flex>
        <Grid
          maxW="700px"
          gridGap={1}
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr"
          mb={8}
        >
          <TeamStat label="Hiring team group" value={client?.name} />
          <TeamStat
            label="Date Added"
            value={formatRelativeDate(client.createdAt)}
          />
        </Grid>
        <Flex alignItems="baseline" mb="3">
          <Heading as="h2" size="sm" mr="4">
            Members
          </Heading>
          <AddUserToClientMembershipButton
            client={client}
            positionIds={client.positions.map((p) => p.id) || []}
            filterUserIds={client?.clientMemberships?.map((u) => u.user.id)}
          />
        </Flex>
        {!!client.clientMemberships && client.clientMemberships?.length < 1 ? (
          <Text size="sm">No members in this hiring team group</Text>
        ) : (
          <Table
            columns={[
              {
                Header: "Name",
                accessor: "fullName",
                Cell: (clientMembership: ClientMembership) => (
                  <>{clientMembership.user.fullName}</>
                ),
              },
              {
                Header: "Email",
                accessor: "email",
                Cell: (clientMembership: ClientMembership) => (
                  <RouterLink
                    to={useLink({
                      type: "userInfo",
                      userId: clientMembership.user.id,
                    })}
                  >
                    {clientMembership.user.email}
                  </RouterLink>
                ),
              },
              {
                Header: "Role",
                accessor: "role",
                Cell: (clientMembership: ClientMembership) => {
                  return (
                    <ClientPositionRoleSelect
                      user={clientMembership.user}
                      client={client}
                      roleId={clientMembership.positionRoleId}
                    />
                  );
                },
              },
              {
                Header: "Remove",
                id: "removebutton",
                Cell: (clientMembership: ClientMembership) => (
                  <RemoveUserFromHiringTeamGroupButton
                    client={client}
                    user={clientMembership.user}
                  />
                ),
              },
            ]}
            data={client.clientMemberships || []}
          />
        )}
        <Flex alignItems="baseline" mb="3" mt="3">
          <Heading as="h2" size="sm" mr="4">
            Positions
          </Heading>
        </Flex>
        {client.positions.length < 1 ? (
          <Text size="sm">No positions in this hiring team group</Text>
        ) : (
          <Table
            columns={[
              {
                Header: "Title",
                accessor: "title",
                Cell: (position: Position) => (
                  <RouterLink to={`/position/${position.id}`}>
                    {position.title}
                  </RouterLink>
                ),
              },
              {
                Header: "Created At",
                id: "createdAt",
                Cell: (position: Position) => (
                  <>{formatDatetime(position.createdAt)}</>
                ),
              },
            ]}
            data={client.positions}
          />
        )}
      </Box>
    </SettingsPageContainer>
  );
};

const TeamStat: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatHelpText>{value}</StatHelpText>
    </Stat>
  );
};

export default HiringTeamGroupInfoPage;
