import { NetworkStatus } from "@apollo/client";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

import { Card, LoadingIndicator } from "../../../../components";
import { itemAnimation, listAnimation } from "../../../../components/Animation";
import { getOffsetDateAsISOString } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddBrightHireToInterviewsMutation,
  ScheduledInterviewListItemFragment,
  useCurrentUserScheduledInterviewsQuery,
} from "../../../graphql";
import useInterval from "../../../hooks/useInterval";
import AddBrightHireBanner from "./AddBrightHireBanner";
import { UpcomingEmptyState, UpcomingErrorState } from "./EmptyState";
import InterviewButtonGroup from "./InterviewButtonGroup";
import ScheduledInterviewsListItem from "./ScheduledInterviewsListItem";

const LONG_REFRESH_DELAY = 1000 * 60 * 5; // 5 minutes
const SHORT_REFRESH_DELAY = 1000 * 10; // 10 seconds
const PAGE_LIMIT = 2;
const LOAD_MORE_PAGE_LIMIT = 2;

const UpcomingInterviews: React.FC = () => {
  const [bannerLoad, setBannerLoad] = useState<number>(1);
  const [refreshDelay, setRefreshDelay] = useState<number>(LONG_REFRESH_DELAY);
  const [updateInterviewIds, setUpdateInterviewIds] = useState<string[]>([]);
  const [failedInterviewIds, setFailedInterviewIds] = useState<string[]>([]);
  const [date] = useState<string>(getOffsetDateAsISOString({ hours: -1 }));
  const [addedBrightHireResults, setAddedBrightHireResults] = useState<
    AddBrightHireToInterviewsMutation | undefined
  >(undefined);

  const sendGAEvent = useSendGAEvent();
  const onClickLink = (itemType: string): void =>
    sendGAEvent("Link to", "home_page", itemType, "Upcoming");

  const { data, loading, error, refetch, variables, networkStatus } =
    useCurrentUserScheduledInterviewsQuery({
      onError: () => {
        reloadBanner();
      },
      variables: {
        start: date,
        end: null,
        positionId: null,
        pagination: {
          limit: PAGE_LIMIT,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });
  let scheduledInterviews: ScheduledInterviewListItemFragment[] =
    data?.currentUser?.scheduledInterviews?.results || [];
  const limit = variables?.pagination?.limit;
  if (limit) {
    scheduledInterviews = scheduledInterviews.slice(0, limit);
  }
  const pageInfo = data?.currentUser?.scheduledInterviews.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  useEffect(() => {
    // Deal with page initialization
    if (scheduledInterviews.length > 1) {
      reloadBanner();
    }

    // If all potential updates for visible interviews are resolved, go back
    // to the long delay and reload the banner
    if (refreshDelay === SHORT_REFRESH_DELAY) {
      const allImported = scheduledInterviews.every((si) => si.isImported);
      if (allImported) {
        setUpdateInterviewIds([]);
        setRefreshDelay(LONG_REFRESH_DELAY);
      }
    }
  }, [scheduledInterviews.length]);

  useEffect(() => {
    if (addedBrightHireResults?.addBrightHireToInterviews) {
      const res = addedBrightHireResults?.addBrightHireToInterviews;

      // Handle updating interviews
      if (res?.scheduledInterviews?.length) {
        const ids = res.scheduledInterviews
          .map((i) => i.id)
          .concat(updateInterviewIds);
        setUpdateInterviewIds(ids);
        setRefreshDelay(SHORT_REFRESH_DELAY);
      }

      // Handle failed interviews
      if (res?.errors?.length) {
        const ids = res.errors.map((i) => i.id);
        setFailedInterviewIds(ids.concat(failedInterviewIds));
      }

      // Reload the banner
      reloadBanner();
    }
  }, [addedBrightHireResults]);

  /**
   * Force a particular scheduled interview to appear updating
   * @param id Scheduled interview ID
   */
  const showUpdating = (
    id: string
  ): "updating" | "updateFailed" | undefined => {
    const failedInterviewIds =
      addedBrightHireResults?.addBrightHireToInterviews?.errors?.map((e) => {
        return e.id;
      }) ?? [];
    if (updateInterviewIds.includes(id)) {
      return "updating";
    }
    if (failedInterviewIds.includes(id)) {
      return "updateFailed";
    }
    return undefined;
  };

  /**
   * Run when the user wants to load another page of results
   */
  const onLoadMore = (): void => {
    refetch({
      pagination: {
        limit: scheduledInterviews.length + LOAD_MORE_PAGE_LIMIT,
      },
    });
  };

  /**
   * Polling + fetchMore is a little quirky with the cache,
   * so we use useInterval to implement pollInterval
   */
  const refresh = useCallback(() => {
    refetch({
      pagination: {
        limit: Math.max(scheduledInterviews.length, PAGE_LIMIT),
      },
    });
  }, [scheduledInterviews.length]);

  useInterval(refresh, refreshDelay);

  const reloadBanner = (): void => {
    setBannerLoad(bannerLoad + 1);
  };

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;

  return (
    <Box data-testid="upcoming-interviews" mb="12">
      <Flex justifyContent="space-between" alignItems="center" mb="5">
        <Heading
          lineHeight="7"
          size="md"
          fontWeight="semibold"
          data-testid="homepage-module-title-upcoming-interviews"
          data-tour-id="upcoming"
        >
          Upcoming interviews
        </Heading>
        <InterviewButtonGroup />
      </Flex>
      <Card
        pt={0}
        pb={0}
        px={0}
        variant="transparent"
        maxW="100%"
        overflowX="auto"
      >
        <AddBrightHireBanner
          onAddBrightHire={(results) => setAddedBrightHireResults(results)}
          updateInterviewIds={updateInterviewIds}
          loadCount={bannerLoad}
        />
        {!error && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={listAnimation}
            key="upcoming-interviews-list"
          >
            {scheduledInterviews.map((scheduledInterview, i) => {
              return (
                <motion.div
                  variants={itemAnimation}
                  data-testid={`upcoming-interviews-row-${i + 1}`}
                  key={`upcoming-interviews-row-${i + 1}`}
                >
                  <ScheduledInterviewsListItem
                    scheduledInterview={scheduledInterview}
                    listPosition={i + 1}
                    key={scheduledInterview.id}
                    onAddBrightHire={(results) =>
                      setAddedBrightHireResults(results)
                    }
                    showUpdating={showUpdating(scheduledInterview.id)}
                    onClickCandidate={() => onClickLink("Candidate")}
                    onClickPosition={() => onClickLink("Position")}
                    onClickInterview={() => onClickLink("Interview")}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
        {!isLoading &&
          !error &&
          scheduledInterviews.length > 0 &&
          pageInfo.hasNextPage && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 32 }}
            >
              <Button
                variant="chalk"
                fontSize="sm"
                height="32px"
                fontWeight={500}
                onClick={onLoadMore}
              >
                Load more
              </Button>
            </motion.div>
          )}
        {isLoading && (
          <motion.div
            initial={{ opacity: 0, height: 32 }}
            animate={{ opacity: 1, height: 80 }}
          >
            <LoadingIndicator py={6} delay={0} />
          </motion.div>
        )}
        {!isLoading && !error && scheduledInterviews.length === 0 && (
          <UpcomingEmptyState />
        )}
        {!isLoading && error && <UpcomingErrorState />}
      </Card>
    </Box>
  );
};

export default UpcomingInterviews;
