import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { LoadingIndicator } from "../../../components";
import {
  formatDateDurationLine,
  monthDisplayMap,
  parseDate,
} from "../../../utils/datetime";
import { getUserFriendlyExcludeText } from "../../../utils/scheduledInterview";
import { UserInterviewImportRule } from "../../graphql";
import { useRecordMyInterviews } from "../../graphql/hooks/useRecordMyInterviews";
import SettingsPageContainer from "../../pages/settings/shared/SettingsPageContainer";

type ImportRule = UserInterviewImportRule | "none";

const MyInterviewRecordingOption: React.FC = () => {
  const {
    importRule: fetchedImportRule,
    importRuleLoading,
    updateRule,
    updateRuleLoading,
    scheduledInterviews,
    scheduledInterviewsLoading,
  } = useRecordMyInterviews();

  const [importRule, setImportRule] = useState<ImportRule>(
    fetchedImportRule ?? "none"
  );
  useEffect(() => {
    setImportRule(fetchedImportRule ?? "none");
  }, [fetchedImportRule]);

  const ruleLoading = importRuleLoading || updateRuleLoading;

  return (
    <SettingsPageContainer
      heading="Automatic Recording Options"
      subHeading="Choose when BrightHire joins your ATS interviews. Which interviews do you want to automatically record?"
    >
      <Flex dir="row" gap={3}>
        <Box>
          <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
            Please select and save your preference
          </Box>
          <Flex
            py="2"
            px="3"
            backgroundColor="gray.50"
            height="272px"
            minH="272px"
            width="319px"
            maxW="319px"
            borderRadius="8px"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box width="100%">
              <RadioGroup
                onChange={(rule: ImportRule) => {
                  setImportRule(rule);
                }}
                value={importRule}
                mt="2"
                size="md"
                variant="padded"
                isDisabled={ruleLoading}
              >
                <Stack>
                  <Radio value={UserInterviewImportRule.AllMyInterviews}>
                    <Text fontSize="sm">
                      Automatically record all of my personal ATS interviews
                    </Text>
                  </Radio>
                  <Radio
                    value={UserInterviewImportRule.OnlyInterviewerMyInterviews}
                  >
                    <Text fontSize="sm">
                      Only automatically record my personal ATS interviews when
                      I am the only interviewer
                    </Text>
                  </Radio>
                  <Radio value="none">
                    <Text fontSize="sm">
                      Don&apos;t automatically record my personal ATS interviews
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box>
              <Button
                size="sm"
                disabled={ruleLoading}
                onClick={() => {
                  updateRule(importRule === "none" ? null : importRule);
                }}
              >
                Save
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
            Upcoming interviews BrightHire will join
          </Box>
          <Box
            py="2"
            px="3"
            border="1px"
            borderColor="gray.200"
            borderRadius="8px"
            height="272px"
            minH="272px"
            maxH="272px"
            overflowY="scroll"
            width="319px"
            maxW="319px"
          >
            {scheduledInterviewsLoading && <LoadingIndicator />}
            {!scheduledInterviewsLoading &&
              scheduledInterviews &&
              scheduledInterviews.length < 1 && (
                <Box textColor="gray.800" fontWeight="600">
                  No interviews
                </Box>
              )}
            {!scheduledInterviewsLoading &&
              scheduledInterviews?.map((interview) => (
                <Tooltip
                  label={
                    interview.isImported === true
                      ? "Event is set to be imported. Please note that only events with video conference links will be able to be captured automatically."
                      : interview.isExcluded === true
                      ? getUserFriendlyExcludeText(
                          interview.importDecisionReason
                        )
                      : "This event can be imported."
                  }
                  key={interview.id}
                >
                  <Box
                    py="1"
                    px="2"
                    border="1px"
                    borderRadius="8px"
                    borderColor={
                      interview.isImported === true
                        ? "blue.500"
                        : interview.isExcluded === true
                        ? "red.500"
                        : "gray.200"
                    }
                    backgroundColor={
                      interview.isImported === true
                        ? "blue.50"
                        : interview.isExcluded === true
                        ? "red.50"
                        : "white"
                    }
                    width="100%"
                    textColor="gray.800"
                    mb="1"
                  >
                    <Box fontSize="xs" fontWeight="600">
                      {interview.name ||
                        (interview.ghJobStageName &&
                          interview.candidate?.fullName &&
                          `${interview.ghJobStageName} - ${interview.candidate?.fullName}`)}
                    </Box>
                    <Box fontSize="xs" fontWeight="400">
                      {
                        monthDisplayMap[
                          parseDate(interview.scheduledStart).getMonth()
                        ]
                      }{" "}
                      {parseDate(interview.scheduledStart).getDate()} {" • "}
                      {formatDateDurationLine(
                        interview.scheduledStart,
                        interview.scheduledEnd
                      )}
                    </Box>
                  </Box>
                </Tooltip>
              ))}
          </Box>
        </Box>
      </Flex>
    </SettingsPageContainer>
  );
};

export default MyInterviewRecordingOption;
