import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { CenteredCardLayout, useToast } from "../../../components";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import GoogleAuthButton from "../../components/GoogleAuthButton";
import MsftAuthButton from "../../components/MsftAuthButton";
import OktaAuthButton from "../../components/OktaAuthButton";
import { useCurrentUserQuery, useSignOutMutation } from "../../graphql";
import SignInByEmailForm from "./SignInByEmailForm";
import SignInByPhoneForm from "./SignInByPhoneForm";
import VerifySignInByEmailForm from "./VerifySignInByEmailForm";
import VerifySignInByPhoneForm from "./VerifySignInByPhoneForm";

const SignInPage: React.FC = () => {
  usePageTracker("sign_in");
  const location = useLocation();
  const state = location?.state as { from?: string } | undefined;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from") || state?.from;
  const redirectTo = from || "/";
  const loginSelector = searchParams.get("with");
  const showGoogleAuth = loginSelector === "google" || loginSelector == null;
  const showMsftAuth = loginSelector === "msft" || loginSelector == null;
  const showOktaAuth = loginSelector == null;
  const showPhoneAuth = loginSelector == null;
  const showEmailAuth = loginSelector == null;
  const showInstructions = loginSelector == null;

  const { data } = useCurrentUserQuery();
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();

  const [signOut] = useSignOutMutation();

  const [displayState, setDisplayState] = useState("default");
  const [verificationInfo, setVerificationInfo] = useState<{
    phoneNumber: string;
    phoneNumberExtension: string;
    channel: "SMS" | "CALL";
  } | null>(null);

  const onVerificationCodeSent = (info: {
    phoneNumber: string;
    phoneNumberExtension: string;
    channel: "SMS" | "CALL";
  }): void => {
    setVerificationInfo(info);
    setDisplayState("verify-phone");
  };

  const onResendVerification = (): void => {
    setDisplayState("input-phone");
  };

  const [verificationByEmailInfo, setVerificationByEmailInfo] = useState<{
    email: string;
  } | null>(null);

  const onVerificationByEmailCodeSent = (info: { email: string }): void => {
    setVerificationByEmailInfo(info);
    setDisplayState("verify-email");
  };

  const onResendVerificationByEmail = (): void => {
    setDisplayState("input-email");
  };

  const onSignIn = (): void => {
    sendGAEvent("sign_in", "auth");
    navigate(redirectTo);
  };

  const phoneNumber = verificationInfo?.phoneNumber;
  const phoneNumberExtension = verificationInfo?.phoneNumberExtension;

  const email = verificationByEmailInfo?.email;

  const currentUser = data?.currentUser;
  useEffect(() => {
    if (
      currentUser &&
      displayState === "default" &&
      !verificationInfo &&
      !verificationByEmailInfo
    ) {
      signOut();
    }
  }, [currentUser]);

  return (
    <CenteredCardLayout containerProps={{ alignItems: "center" }}>
      <Flex
        pt={8}
        flexDirection="column"
        alignItems="center"
        width={["100%", "400px"]}
      >
        {displayState === "default" && (
          <>
            {showInstructions && (
              <>
                <Heading as="h3" mb={2} fontSize="2xl" fontWeight="normal">
                  First time and returning users
                </Heading>
                <Box mb={4} textAlign="center" fontSize="sm">
                  Continue with one of the authentication options below
                </Box>
              </>
            )}
            {showGoogleAuth && (
              <GoogleAuthButton
                onError={({ message }) => {
                  toast({
                    title: "Error",
                    description: message,
                    status: "error",
                    duration: 30000,
                  });
                }}
                label={
                  showInstructions
                    ? "Continue with Google"
                    : "Login with Google"
                }
                onAuth={onSignIn}
                feature="auth"
              />
            )}
            {showMsftAuth && (
              <Box mt={showInstructions ? 4 : 0} width="100%">
                <MsftAuthButton
                  label={
                    showInstructions
                      ? "Continue with Microsoft"
                      : "Login with Microsoft"
                  }
                  onError={({ message }) => {
                    toast({
                      title: "Error",
                      description: message,
                      status: "error",
                      duration: 30000,
                    });
                  }}
                  onAuth={onSignIn}
                  feature="auth"
                />
              </Box>
            )}
            {showOktaAuth && (
              <Box mt={showInstructions ? 4 : 0} width="100%">
                <OktaAuthButton
                  label={
                    showInstructions ? "Continue with SSO" : "Login with SSO"
                  }
                />
              </Box>
            )}
            {showPhoneAuth && (
              <Box mt={showInstructions ? 4 : 0} width="100%">
                <Button
                  leftIcon={<FaPhoneAlt />}
                  width="100%"
                  onClick={() => {
                    setDisplayState("input-phone");
                  }}
                  data-testid="phone-auth-button"
                >
                  {showInstructions
                    ? "Continue with phone"
                    : "Login with phone"}
                </Button>
              </Box>
            )}
            {showEmailAuth && (
              <Box mt={showInstructions ? 4 : 0} width="100%">
                <Button
                  leftIcon={<MdEmail />}
                  width="100%"
                  onClick={() => {
                    setDisplayState("input-email");
                  }}
                  data-testid="email-auth-button"
                >
                  {showInstructions
                    ? "Continue with email"
                    : "Login with email"}
                </Button>
              </Box>
            )}
          </>
        )}
        {displayState === "input-phone" && (
          <>
            <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
              Sign in by phone
            </Heading>
            <SignInByPhoneForm
              phoneNumber={phoneNumber}
              phoneNumberExtension={phoneNumberExtension}
              onVerificationCodeSent={onVerificationCodeSent}
            />
          </>
        )}
        {displayState === "verify-phone" && verificationInfo && (
          <>
            <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
              Sign in by phone
            </Heading>
            <Box px={6} width="342px">
              <VerifySignInByPhoneForm
                {...verificationInfo}
                onSignIn={onSignIn}
                onResendVerification={onResendVerification}
              />
            </Box>
          </>
        )}
        {displayState === "input-email" && (
          <>
            <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
              Sign in by email
            </Heading>
            <SignInByEmailForm
              email={email}
              onVerificationCodeSent={onVerificationByEmailCodeSent}
            />
          </>
        )}
        {displayState === "verify-email" && verificationByEmailInfo && (
          <>
            <Heading as="h3" mb={4} fontSize="2xl" fontWeight="normal">
              Sign in by email
            </Heading>
            <Box px={6} width="342px">
              <VerifySignInByEmailForm
                {...verificationByEmailInfo}
                onSignIn={onSignIn}
                onResendVerification={onResendVerificationByEmail}
              />
            </Box>
          </>
        )}
      </Flex>
    </CenteredCardLayout>
  );
};

export default SignInPage;
