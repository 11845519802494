import { Flex, FlexProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineVideoCamera } from "react-icons/hi2";

import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ScheduledInterviewListItemFragment } from "../../graphql";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import { ColumnHeaderBorder, ColumnHeading } from "./ColumnHeading";
import InterviewListItemV2 from "./InterviewListItemV2";
import SummaryTabV2 from "./summary/SummaryTabV2";
import { CandidateCall } from "./types";

type InterviewTabProps = {
  calls: Array<CandidateCall>;
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  currentUserNotesOnly: boolean;
  showHighlightsForInterview(callId: string): void;
  candidateId: string;
  positionId?: string;
};

const InterviewTabV2: React.FC<InterviewTabProps> = ({
  calls,
  scheduledInterviews,
  currentUserNotesOnly,
  showHighlightsForInterview,
  candidateId,
  positionId,
}) => {
  const [interviewsExpanded, setInterviewsExpanded] = useState(true);
  const { windowHeight } = useWindowDimensions();
  const topRef = React.useRef<HTMLDivElement>(null);
  const [, setForceRender] = useState(false);

  const topWidth = topRef.current?.offsetWidth;
  // We can't calculate full layout until we know the width of the top container,
  // and setting the ref doesn't trigger a re-render, so we need to force one.
  useEffect(() => {
    if (topWidth === undefined) {
      setForceRender(true);
    }
  }, []);

  // We manually calculate dimensions so we can size the inner width of the offscreen
  // debrief content to the size that will be available after it is expanded, so it
  // animates smoothly.
  const dimensions = columnDimensions(topWidth || 0, interviewsExpanded);
  const currentBreakpoint = useCurrentBreakpoint();
  const isExtension = useIsExtension();

  if (currentBreakpoint === "sm" || isExtension) {
    return (
      <Flex flexDir="column" gap={4}>
        <SummaryTabV2
          candidateId={candidateId}
          positionId={positionId}
          notesExpanded={!interviewsExpanded}
          toggleNotes={() => setInterviewsExpanded(!interviewsExpanded)}
          innerExpandedWidth={dimensions.debriefInnerExpandedWidth}
        />
        <RecordingsColumn
          scheduledInterviews={scheduledInterviews}
          calls={calls}
          currentUserNotesOnly={currentUserNotesOnly}
          showHighlightsForInterview={showHighlightsForInterview}
          interviewsExpanded={interviewsExpanded}
          dimensions={dimensions}
        />
      </Flex>
    );
  }

  return (
    <Flex
      ref={topRef}
      flexDir="row"
      gap={4}
      height="100%" // TODO: "undefined" when no content
      maxH={parseInt(windowHeight) - 238}
    >
      {topWidth && (
        <>
          <ColumnWrapper
            width={dimensions.recordingsColumnWidth}
            minWidth={dimensions.recordingsColumnMinWidth}
            transition="width 0.3s"
            pb={0}
          >
            <RecordingsColumn
              scheduledInterviews={scheduledInterviews}
              calls={calls}
              currentUserNotesOnly={currentUserNotesOnly}
              showHighlightsForInterview={showHighlightsForInterview}
              interviewsExpanded={interviewsExpanded}
              dimensions={dimensions}
            />
          </ColumnWrapper>

          <ColumnWrapper
            width={dimensions.debriefColumnWidth}
            minWidth={dimensions.debriefColumnMinWidth}
            transition="width 0.3s"
          >
            <SummaryTabV2
              candidateId={candidateId}
              positionId={positionId}
              notesExpanded={!interviewsExpanded}
              toggleNotes={() => setInterviewsExpanded(!interviewsExpanded)}
              innerExpandedWidth={dimensions.debriefInnerExpandedWidth}
            />
          </ColumnWrapper>
        </>
      )}
    </Flex>
  );
};

const RecordingsColumn: React.FC<{
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  calls: Array<CandidateCall>;
  currentUserNotesOnly: boolean;
  showHighlightsForInterview(callId: string): void;
  interviewsExpanded: boolean;
  dimensions: ColumnDimensions;
}> = ({
  scheduledInterviews,
  calls,
  currentUserNotesOnly,
  showHighlightsForInterview,
  interviewsExpanded,
  dimensions,
}) => {
  return (
    <Flex flexDir="column" flex="1 1 auto" overflow="hidden">
      <ColumnHeading iconType={HiOutlineVideoCamera} title="Recordings" />
      <ColumnHeaderBorder />
      <Flex
        direction="column"
        overflow="auto"
        minWidth={dimensions.recordingsInnerExpandedWidth}
      >
        {scheduledInterviews.length > 0 &&
          scheduledInterviews.map((scheduledInterview, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={scheduledInterview.id}
                scheduledInterview={scheduledInterview}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                collapsed={!interviewsExpanded}
              />
            );
          })}
        {calls.length > 0 &&
          calls.map((call, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={call.id}
                call={call}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                onShowHighlights={() => showHighlightsForInterview(call.id)}
                collapsed={!interviewsExpanded}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

type ColumnDimensions = {
  recordingsColumnWidth: string;
  recordingsColumnMinWidth: string;
  recordingsInnerExpandedWidth: string;
  debriefColumnWidth: string;
  debriefColumnMinWidth: string;
  debriefInnerExpandedWidth: string;
};

const columnDimensions = (
  totalWidth: number,
  expanded: boolean
): ColumnDimensions => {
  const recordingsColumnMinWidth = 257;
  const debriefColumnMinWidth = 420;
  const recordingsInnerExpandedWidth =
    totalWidth -
    debriefColumnMinWidth - // recordings column width
    16 - // gap between columns
    24 - // padding on debrief column
    2; // border on column

  const debriefInnerExpandedWidth =
    totalWidth -
    recordingsColumnMinWidth - // recordings column width
    16 - // gap between columns
    24 - // padding on debrief column
    2; // border on column
  if (expanded) {
    return {
      recordingsColumnWidth: "100%",
      recordingsColumnMinWidth: `${recordingsColumnMinWidth}px`,
      recordingsInnerExpandedWidth: `${recordingsInnerExpandedWidth}px`,
      debriefColumnWidth: `${debriefColumnMinWidth}px`,
      debriefColumnMinWidth: `${debriefColumnMinWidth}px`,
      debriefInnerExpandedWidth: `${debriefInnerExpandedWidth}px`,
    };
  }

  return {
    recordingsColumnWidth: `${recordingsColumnMinWidth}px`,
    recordingsColumnMinWidth: `${recordingsColumnMinWidth}px`,
    recordingsInnerExpandedWidth: `${recordingsInnerExpandedWidth}px`,
    debriefColumnWidth: "100%",
    debriefColumnMinWidth: `${debriefColumnMinWidth}px`,
    debriefInnerExpandedWidth: `${debriefInnerExpandedWidth}px`,
  };
};

const ColumnWrapper: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      h="fit-content"
      maxH="100%"
      border="1px"
      borderColor="gray.100"
      borderRadius="2xl"
      py={4}
      px={6}
      overflow="hidden"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default InterviewTabV2;
