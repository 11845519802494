import { Box, Flex, StyleProps, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import MultiValueSelect from "../../../components/MultiValueSelect/MultiValueSelect";
import {
  formatDateDurationLine,
  monthDisplayMap,
} from "../../../utils/datetime";
import {
  PrimaryCalendarEventsQuery,
  useCreateEventImportKeywordMutation,
  useCurrentUserEventImportKeywordsQuery,
  useDeleteEventImportKeywordMutation,
  usePrimaryCalendarEventsQuery,
} from "../../graphql";

type Event = PrimaryCalendarEventsQuery["primaryCalendarEvents"][0];
type EventListItem = {
  month: number;
  days: {
    day: number;
    dayOfWeek: string;
    events: Event[];
  }[];
};

const getDayAbbreviation = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { weekday: "short" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const buildEventList = (calendarEvents: Event[]): EventListItem[] => {
  const eventList: EventListItem[] = [];
  let prevMonth: number;

  calendarEvents.forEach((item) => {
    const startDate = new Date(item.start);
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();
    // Group by months
    if (startMonth !== prevMonth) {
      eventList.push({
        month: startMonth,
        days: [
          {
            day: startDay,
            dayOfWeek: getDayAbbreviation(startDate),
            events: [item],
          },
        ],
      });
      prevMonth = startMonth;
    } else {
      // Group by days
      const lastEvent = eventList[eventList.length - 1];
      const lastDay = lastEvent.days[lastEvent.days.length - 1];
      if (lastDay.day !== startDay) {
        lastEvent.days.push({
          day: startDay,
          dayOfWeek: getDayAbbreviation(startDate),
          events: [item],
        });
      } else {
        lastDay.events.push(item);
      }
    }
  });

  return eventList;
};

export const EventImportKeyword: React.FC<StyleProps> = (styles) => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [eventList, setEventList] = useState<EventListItem[]>([]);
  const { data: eventImportKeywordsData } =
    useCurrentUserEventImportKeywordsQuery();
  const { data: eventsData, stopPolling: stopPollingPrimaryCalendar } =
    usePrimaryCalendarEventsQuery({
      // Poll every 5 seconds until we receive events to ensure the calendar sync is complete.
      pollInterval: 5000,
    });

  useEffect(() => {
    const savedKeywords =
      eventImportKeywordsData?.currentUser?.eventImportKeywords;
    if (savedKeywords) {
      setKeywords(savedKeywords.map((item) => item.keywordText));
    }
  }, [eventImportKeywordsData]);
  useEffect(() => {
    const events = eventsData?.primaryCalendarEvents;
    if (events && events.length > 0) {
      setEventList(buildEventList(events));
      stopPollingPrimaryCalendar();
    }
  }, [eventsData]);

  const deleteFromEventList = (
    deletedKeywords: string[],
    currentEventList: EventListItem[]
  ): EventListItem[] => {
    return currentEventList.map((item) => {
      return {
        ...item,
        days: item.days.map((dayItem) => {
          return {
            ...dayItem,
            events: dayItem.events.map((event) => {
              // Removes the need to check excluded
              if (event.importExcludeDecision === false) {
                return { ...event };
              }
              const deletedFound = deletedKeywords.find((keyword) => {
                if (
                  event.summary
                    ?.toLowerCase()
                    .includes(keyword.trim().toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
              return {
                ...event,
                importExcludeDecision: deletedFound // set to nothing if deleted keyword is found
                  ? undefined
                  : event.importExcludeDecision === true // if keyword is not found, then leave the state as is. Interview could be imported for another reason
                  ? true
                  : undefined,
              };
            }),
          };
        }),
      };
    });
  };

  // Used for adding keywords and hard refresh of the page before the backend finishes processing the imports
  useEffect(() => {
    setEventList((currentEventList) => {
      return currentEventList.map((item) => {
        return {
          ...item,
          days: item.days.map((dayItem) => {
            return {
              ...dayItem,
              events: dayItem.events.map((event) => {
                // Removes the need to check excluded: import = true, exclude = false, no decision = undefined
                if (event.importExcludeDecision === false) {
                  return { ...event };
                }
                const addedFound = keywords.find((keyword) => {
                  if (
                    event.summary
                      ?.toLowerCase()
                      .includes(keyword.trim().toLowerCase())
                  ) {
                    return true;
                  }
                  return false;
                });
                return {
                  ...event,
                  importExcludeDecision:
                    addedFound || event.importExcludeDecision === true // if keyword is not found, then leave the state as is. Interview could be imported for another reason
                      ? true
                      : undefined,
                };
              }),
            };
          }),
        };
      });
    });
  }, [keywords, eventList]);

  const [createKeyword] = useCreateEventImportKeywordMutation();
  const addKeyword = (newKeyword: string): void => {
    if (!keywords.includes(newKeyword)) {
      setKeywords((prevKeywords) => [...prevKeywords, newKeyword]);
      createKeyword({ variables: { keywords: [newKeyword] } });
    }
  };

  const [deleteKeywords] = useDeleteEventImportKeywordMutation();
  const handleValueChange = (anyValue: string[]): void => {
    const deletedKeywords = keywords.filter((item) => !anyValue.includes(item));
    if (deletedKeywords.length > 0) {
      setKeywords(anyValue);
      deleteKeywords({ variables: { keywords: deletedKeywords } });
      setEventList((prevList) => {
        return deleteFromEventList(deletedKeywords, prevList);
      });
    }
  };

  return (
    <Flex gap="4" maxH="400" {...styles}>
      <Flex direction="column" flex="1" color="gray.600">
        <Box fontWeight="semibold" fontSize="sm" mb="2">
          Keywords or phrases that identify interviews
        </Box>
        <Box py="2" px="4" backgroundColor="gray.50" borderRadius="lg" flex="1">
          <Text fontSize="sm" mb="1">
            Be specific in choosing your keywords and phrases as BrightHire will
            join all events that contain any of them:
          </Text>
          <MultiValueSelect
            fontSize="sm"
            autoFocus
            value={keywords}
            onAddValue={(keyword) => addKeyword(keyword)}
            onValueChange={handleValueChange}
            getOptionLabel={(s) => s}
            getOptionValue={(s) => s}
            addValueOnBlur
            placeholder="Enter keywords or phrases"
            selectStyles={{
              control: (provided) => ({
                ...provided,
                maxHeight: "150px",
                overflowY: "auto",
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                display: "block",
              }),
              clearIndicator: (provided) => ({
                ...provided,
                position: "sticky",
                top: "0",
              }),
            }}
            helpTextComponent={
              <Text textColor="gray.500" fontWeight="500" fontSize="xs" mt="1">
                Separate keywords with a comma or enter key.
              </Text>
            }
          />
        </Box>
      </Flex>
      <Flex direction="column" flex="1">
        <Box color="gray.600" fontWeight="semibold" fontSize="sm" mb="2">
          Events BrightHire will join
        </Box>
        <Box
          py="2"
          pl="3"
          pr="5"
          flex="1"
          border="1px"
          borderColor="gray.200"
          borderRadius="lg"
          overflowY="scroll"
        >
          {eventList.map((item) => (
            <Box key={item.month} mb="3">
              <Box
                borderRadius="base"
                background="gray.50"
                textAlign="center"
                textColor="gray.800"
                fontSize="xs"
                fontWeight="medium"
                h="5"
                mb="1"
              >
                {monthDisplayMap[item.month]}
              </Box>
              <Flex direction="column" gap="1">
                {item.days.map((dayItem) => (
                  <Flex dir="row" gap="3" pt="2" key={dayItem.day}>
                    <Box textAlign="center" w="8">
                      <Box fontSize="xx-small" textColor="gray.600">
                        {dayItem.dayOfWeek}
                      </Box>
                      <Box textColor="gray.800" fontSize="lg">
                        {dayItem.day}
                      </Box>
                    </Box>
                    <Flex direction="column" flex="1" gap="1">
                      {dayItem.events.map((eventItem) => (
                        <Tooltip
                          openDelay={500}
                          placement="start"
                          label={
                            eventItem.importExcludeDecision === true
                              ? "Event is set to be imported. Please note that only events with video conference links will be able to be captured."
                              : eventItem.importExcludeDecision === false
                              ? "Organization wide import rules are preventing this event from being captured by BrightHire."
                              : "This event can be imported."
                          }
                          key={eventItem.id}
                        >
                          <Box
                            py="1"
                            px="2"
                            border="1px"
                            borderColor={
                              eventItem.importExcludeDecision === true
                                ? "blue.500"
                                : eventItem.importExcludeDecision === false
                                ? "red.500"
                                : "gray.200"
                            }
                            backgroundColor={
                              eventItem.importExcludeDecision === true
                                ? "blue.50"
                                : eventItem.importExcludeDecision === false
                                ? "red.50"
                                : "white"
                            }
                            textColor="gray.800"
                          >
                            <Box fontSize="xs" fontWeight="600">
                              {eventItem.summary}
                            </Box>
                            <Box fontSize="xs" fontWeight="400">
                              {formatDateDurationLine(
                                eventItem.start,
                                eventItem.end
                              )}
                            </Box>
                          </Box>
                        </Tooltip>
                      ))}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};
