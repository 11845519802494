import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import Select from "react-select";

import { LoadingIndicator } from "../../../../../components";
import { useAnalyticsSkillDescriptionLazyQuery } from "../../../../graphql";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import { StepWrapper } from "./SkillsReportBuilderStepOne";

type StepTwoSkill = {
  id: string;
  name: string;
  description: string;
  aiLoading?: boolean;
};

export interface SkillsReportBuilderStepTwoFormData {
  source: string;
  skills: StepTwoSkill[];
}

type StepTwoProps = {
  onComplete: (data: SkillsReportBuilderStepTwoFormData) => void;
  onCancel: () => void;
};

const SkillsReportBuilderStepTwo: React.FC<StepTwoProps> = ({
  onComplete,
  onCancel,
}) => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const defaultValues = {
    source: "custom",
    skills: [{ id: "1", name: "", description: "", aiLoading: false }],
  };
  const { control, register, handleSubmit, setValue, watch } =
    useForm<SkillsReportBuilderStepTwoFormData>({
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const skills = watch("skills");
  const hasAtLeastOneNonEmpty = skills.some((item) => item.name.trim() !== "");

  const onSubmit = (formData: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills } = formData;
    onComplete({ source, skills });
  };

  // TODO: add more options (and probably an enum for communication with the backend)
  const sourceOptions = [
    { value: "custom", label: "Create my own skill list (w/ AI assistant)" },
    { value: "import", label: "Import from ATS job description" },
    { value: "generate", label: "Generate for me using AI" },
    { value: "general", label: "Use BrightHire general skill list" },
  ];

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 340,
      ...containerStyles,
    }),
    ...selectStylesRest,
  };

  const [fetchSkillDescription] = useAnalyticsSkillDescriptionLazyQuery();

  // NOTE: These are demo skills for Shine demo
  // remove during productization and replace with actual data
  const demoSkills = [
    {
      id: "1",
      name: "SaaS Sales Experience",
      description:
        "Managing complex SaaS sales cycles and strategizing to close challenging deals",
    },
    {
      id: "2",
      name: "Talent Acquisition Expertise",
      description:
        "Leveraging up-to-date HR tech trends to significantly impact sales success",
    },
    {
      id: "3",
      name: "Startup Experience",
      description:
        "Adapting swiftly to startup changes and exceeding sales targets in new markets",
    },
    {
      id: "4",
      name: "Sales Cycle Execution",
      description:
        "Executing complex sales cycles from start to finish and overcoming last-minute objections",
    },
    {
      id: "5",
      name: "Sales Process Innovation",
      description:
        "Adapting and innovating sales processes to meet market demands and overcome challenges",
    },
    {
      id: "6",
      name: "Sales Tools Proficiency",
      description:
        "Optimizing sales processes using Sales Navigator, Salesloft, and other tools",
    },
    {
      id: "7",
      name: "Pipeline Management",
      description:
        "Rebuilding and maintaining robust sales pipelines to ensure sufficient coverage",
    },
    {
      id: "8",
      name: "Strategic Account Development",
      description:
        "Identifying and developing multiple stakeholders within accounts for deal success",
    },
    {
      id: "9",
      name: "Complex Deal Navigation",
      description:
        "Navigating complex deals through procurement, legal, and security challenges",
    },
    {
      id: "10",
      name: "Ownership Mentality",
      description:
        "Taking full ownership of projects to address significant issues impacting sales cycles",
    },
    {
      id: "11",
      name: "Go-to-Market Strategy Contribution",
      description:
        "Contributing to and iterating on go-to-market strategies to shape effective sales narratives",
    },
  ];

  return (
    <StepWrapper>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="2xl" color="gray.900" fontWeight="600">
          Report builder
        </Text>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          disabled={fields.length === 0 || !hasAtLeastOneNonEmpty}
          onClick={handleSubmit(onSubmit)}
        >
          Run report
        </Button>
      </Flex>
      <Divider my="6" borderColor="gray.200" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl maxWidth="300px">
          <FormLabel color="gray.600" mb="1">
            Skills source
          </FormLabel>
          <Select
            {...register("source")}
            styles={commonSelectStyles}
            theme={selectTheme}
            options={sourceOptions}
            defaultValue={sourceOptions[0]}
            onChange={(option) => {
              setValue("source", option?.value || "");
              if (option?.value === "import") {
                setValue("skills", demoSkills);
              } else {
                setValue("skills", defaultValues.skills);
              }
            }}
          />
        </FormControl>
        <Text mt="5" mb="2" color="gray.600" fontWeight="400" fontSize="sm">
          Skills
        </Text>
        <VStack
          spacing="4"
          alignItems="flex-start"
          backgroundColor="gray.50"
          py="5"
          px="4"
          borderRadius="12px"
          mb="8"
        >
          {fields.map((field, index) => (
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyItems="flex-start"
              width="100%"
              key={field.id}
            >
              <Input
                width="280px"
                mr="2"
                placeholder="Skill"
                required
                _placeholder={{ color: "gray.600" }}
                {...register(`skills.${index}.name`, {
                  onBlur: (e) => {
                    const skillName = e.target.value.trim();
                    const skillDescription = skills[index].description.trim();
                    if (
                      skillName &&
                      skillName !== "" &&
                      skillDescription === ""
                    ) {
                      setValue(`skills.${index}.aiLoading`, true);
                      fetchSkillDescription({
                        variables: {
                          skillName,
                        },
                        onCompleted: (data) => {
                          const description =
                            data.skillDescription?.description;
                          setValue(
                            `skills.${index}.description`,
                            description || ""
                          );
                          setValue(`skills.${index}.aiLoading`, false);
                        },
                      });
                    }
                  },
                })}
              />
              <Flex mr="2" flexGrow="1">
                <InputGroup>
                  {skills[index].aiLoading && (
                    <InputLeftElement>
                      <LoadingIndicator size="sm" color="purple.500" />
                    </InputLeftElement>
                  )}
                  <Input
                    required
                    placeholder={
                      skills[index].aiLoading
                        ? ""
                        : "Optional description or examples"
                    }
                    _placeholder={{ color: "gray.400" }}
                    isDisabled={skills[index].aiLoading}
                    {...register(`skills.${index}.description`)}
                  />
                </InputGroup>
              </Flex>
              <IconButton
                aria-label="remove skill"
                type="button"
                // colorScheme="red"
                variant="icon"
                backgroundColor="transparent"
                size="sm"
                icon={<Icon as={HiOutlineTrash} boxSize="5" />}
                onClick={() => remove(index)}
              />
            </Flex>
          ))}
          <Button
            aria-label="add skill"
            variant="ghost"
            fontWeight="500"
            size="sm"
            leftIcon={<Icon as={HiOutlinePlus} boxSize="5" />}
            onClick={() => {
              append({
                id: `${fields.length + 1}`,
                name: "",
                description: "",
              });
            }}
          >
            Add skill
          </Button>
        </VStack>
      </form>
      <Divider borderColor="gray.200" />
      <Flex flexDir="row" justifyContent="space-between" mt="6">
        <Button variant="ghost" fontWeight="500" size="sm" onClick={onCancel}>
          Back
        </Button>
        <Button
          variant="solid"
          size="sm"
          fontWeight="500"
          onClick={handleSubmit(onSubmit)}
          disabled={fields.length === 0 || !hasAtLeastOneNonEmpty}
        >
          Run report
        </Button>
      </Flex>
    </StepWrapper>
  );
};

export default SkillsReportBuilderStepTwo;
