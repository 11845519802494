// Feature flagged over tab shellimport {

import {
  Box,
  Button,
  Flex,
  IconButton,
  Select,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import { EmptyAIIcon } from "../../../../components";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { copy } from "../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateSummaryProcessingStatus,
  CandidateSummaryTemplateFragment,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useTrackOnce from "../../../hooks/useTrackOnce";
import AiNotesLoading from "../../Recording/SidebarTabs/Ai/Notes/NotesQA/AiNotesLoading";
import Notes from "./Notes";
import TopicCoverageList from "./TopicCoverageList";
import TopicsAndNotes from "./TopicsAndNotes";
import { CandidateSummary, Note, SubTopic, Topic } from "./types";
import { useShowEditCandidateSummaryTemplate } from "./useCanEditCandidateSummartTemplate";
import { formatTopic } from "./utils";

type SummaryTabContentProps = {
  selectedTemplate: string;
  candidateId: string;
  handleChangeTemplate: (templateId: string) => void;
  data?: CandidateSummary | null;
  templates?: CandidateSummaryTemplateFragment[] | null;
  generateSummary?: (templateId: string) => void;
};

const SummaryTabContent: React.FC<SummaryTabContentProps> = ({
  selectedTemplate,
  candidateId,
  handleChangeTemplate,
  data,
  templates,
  generateSummary,
}) => {
  const { windowHeight } = useWindowDimensions();
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const topics = useMemo(() => {
    if (data) {
      return reshapeNotes(data);
    }
  }, [data]);

  const citationMap = useMemo(() => {
    let citationCount = 0;
    const cMap: { [key: string]: number } = {};
    topics?.forEach((topic) => {
      topic.subTopics?.forEach((subTopic) => {
        subTopic.notes?.forEach((note) => {
          note.citations.forEach((citation) => {
            citationCount += 1;
            cMap[citation.id] = citationCount;
          });
        });
      });
    });
    return cMap;
  }, [topics]);

  const formatAllTopics = (
    copyingTopics: Topic[]
  ): { plain: string; html: string } => {
    let plain = "";
    let html = "";

    copyingTopics?.forEach((topic) => {
      const res = formatTopic(topic, citationMap);
      plain += `${res.plain}\n\n`;
      html += `${res.html}<br>`;
    });
    return { plain, html };
  };

  const copyAllTopics = (): void => {
    if (!topics) {
      return;
    }
    const result = formatAllTopics(topics);
    copy({ plain: result.plain, html: result.html });
    toast({
      title: "Debrief copied to clipboard",
      status: "success",
      position: "top",
    });
    sendGAEvent("candidate_summary_copy_all", "call_review");
  };

  const summaryLoading =
    data &&
    data?.status !== CandidateSummaryProcessingStatus.Completed &&
    data?.status !== CandidateSummaryProcessingStatus.Failed;

  const isExtension = useIsExtension();

  const trackFirstScroll = useTrackOnce("candidate_summary_note_scroll");

  return (
    <Flex
      flexDir="row"
      gap={4}
      height={topics ? "100%" : undefined}
      mt={3}
      maxH={parseInt(windowHeight) - 272}
      maxW="1280px"
      mx="auto"
      flex="1"
    >
      {isExtension ? (
        <SummaryExtensionViewWrapper
          templates={templates}
          summaryExists={!!topics}
          generateSummary={generateSummary}
          handleChangeTemplate={handleChangeTemplate}
          selectedTemplate={selectedTemplate}
          copyAll={copyAllTopics}
          candidateId={candidateId}
        >
          {summaryLoading && (
            <AiNotesLoading loadingText="Generating summary" />
          )}
          {!summaryLoading && (!topics || topics.length < 1) && (
            <Box
              alignItems="center"
              display="flex"
              flexDir="column"
              h="100%"
              width="100%"
              pb="4"
              gap="4"
            >
              <EmptyAIIcon mb="5" maxW="300px" />
              <Text align="center" maxW="300px" textAlign="center">
                No candidate debrief yet.
              </Text>
              <Button>Generate Debrief</Button>
              <Text maxW="300px" textAlign="center">
                This may take up to 30 seconds, depending on the number of
                interviews.
              </Text>
            </Box>
          )}
          {topics && !summaryLoading && topics.length > 0 && (
            <Box overflow="auto" mb={2} onScroll={trackFirstScroll}>
              <TopicsAndNotes
                topics={topics}
                onClickTimestamp={(callId: string, t: number) => {
                  window.open(`/interview/${callId}?t=${t}`);
                }}
              />
            </Box>
          )}
        </SummaryExtensionViewWrapper>
      ) : (
        <SummaryAndCoverageWrapper
          templates={templates}
          summaryExists={!!topics}
          generateSummary={generateSummary}
          handleChangeTemplate={handleChangeTemplate}
          selectedTemplate={selectedTemplate}
          copyAll={copyAllTopics}
          candidateId={candidateId}
        >
          {summaryLoading && (
            <AiNotesLoading loadingText="Generating summary" />
          )}
          {!summaryLoading && (!topics || topics.length < 1) && (
            <Box
              alignItems="center"
              display="flex"
              flexDir="column"
              h="100%"
              width="100%"
              pb="4"
              gap="4"
            >
              <EmptyAIIcon mb="5" maxW="380px" />
              <Text align="center" maxW="380px" textAlign="center">
                No candidate debrief yet. Generating debrief notes will
                summarize all interviews for this candidate and organize them by
                competencies, when available. Try it out now.
              </Text>
              <Button
                onClick={() => {
                  if (selectedTemplate && generateSummary) {
                    generateSummary(selectedTemplate);
                  }
                }}
              >
                Generate Debrief
              </Button>
              <Text maxW="380px" textAlign="center">
                This may take up to 30 seconds, depending on the number of
                interviews.
              </Text>
            </Box>
          )}
          {topics && topics.length > 0 && !summaryLoading && (
            <Flex flexDir="row" overflow="hidden">
              <Box flex="1" overflow="auto">
                <TopicCoverageList topics={topics} />
              </Box>
              <Box flex="2" overflow="auto" mb={2} onScroll={trackFirstScroll}>
                <Notes
                  topics={topics}
                  citationMap={citationMap}
                  onClickTimestamp={(callId: string, t: number) => {
                    window.open(`/interview/${callId}?t=${t}`);
                  }}
                />
              </Box>
            </Flex>
          )}
        </SummaryAndCoverageWrapper>
      )}
    </Flex>
  );
};

type SummaryAndCoverageWrapperProps = {
  children: React.ReactNode;
  candidateId: string;
  selectedTemplate: string;
  handleChangeTemplate: (templateId: string) => void;
  templates?: CandidateSummaryTemplateFragment[] | null;
  summaryExists?: boolean;
  generateSummary?: (templateId: string) => void;
  copyAll: () => void;
};

const SummaryAndCoverageWrapper = ({
  children,
  candidateId,
  selectedTemplate,
  handleChangeTemplate,
  templates,
  summaryExists,
  generateSummary,
  copyAll,
}: SummaryAndCoverageWrapperProps): JSX.Element => {
  const navigate = useNavigate();
  const currentTemplate = templates?.find(
    (item) => item.id === selectedTemplate
  );
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  const showEdit = useShowEditCandidateSummaryTemplate();
  return (
    <Flex
      flexDir="column"
      h="100%"
      border="1px"
      borderColor="gray.100"
      borderRadius="2xl"
      py={4}
      overflow="hidden"
      flex={5}
    >
      <Flex
        flexDir="row"
        borderBottom="1px"
        borderColor="gray.200"
        pb={3}
        mb={6}
        mx={6}
        alignItems="center"
      >
        <Flex flexDir="row" alignItems="center" gap={2} marginRight="auto">
          <Text
            fontSize="sm"
            color="gray.600"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            Debrief template
          </Text>
          <Select
            size="sm"
            borderRadius="md"
            onChange={(e) => {
              handleChangeTemplate(e.target.value);
            }}
            value={selectedTemplate}
          >
            {templates?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
          {!summaryExists && templates && generateSummary && (
            <Tooltip label="Generates using all public interviews for the candidate">
              <Button
                fontSize="sm"
                size="sm"
                minW="140px"
                disabled={isGenerateDisabled}
                onClick={() => {
                  setIsGenerateDisabled(true);
                  generateSummary(selectedTemplate);
                }}
              >
                Generate Debrief
              </Button>
            </Tooltip>
          )}
          {templates &&
            !currentTemplate?.isOrgDefault &&
            currentTemplate &&
            showEdit && (
              <Tooltip label="Edit">
                <IconButton
                  icon={<HiOutlineCog8Tooth />}
                  fontSize="20px"
                  aria-label="edit"
                  variant="icon"
                  onClick={() =>
                    navigate(
                      `/debrief/${currentTemplate.id}/edit?candidateId=${candidateId}`
                    )
                  }
                />
              </Tooltip>
            )}
        </Flex>
        <Tooltip label="Copy to clipboard">
          <IconButton
            isDisabled={!currentTemplate}
            variant="icon"
            aria-label="Copy to clipboard"
            onClick={() => copyAll()}
            icon={
              <HiOutlineClipboardCopy
                style={{ transform: "scaleX(-1)" }}
                strokeWidth="1.5px"
                size={20}
              />
            }
          />
        </Tooltip>
      </Flex>
      {children}
    </Flex>
  );
};

const SummaryExtensionViewWrapper = ({
  children,
  selectedTemplate,
  handleChangeTemplate,
  templates,
  summaryExists,
  generateSummary,
  copyAll,
}: SummaryAndCoverageWrapperProps): JSX.Element => {
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  return (
    <Flex
      flexDir="column"
      h="100%"
      border="1px"
      borderColor="gray.100"
      borderRadius="2xl"
      py={4}
      flex={5}
    >
      <Flex
        dir="row"
        mx={6}
        pb={1}
        mb={4}
        borderBottom="1px"
        borderColor="gray.200"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Text fontWeight={600}>Candidate Summary</Text>
        </Box>
        <Tooltip label="Copy to clipboard">
          <IconButton
            variant="icon"
            aria-label="Copy to clipboard"
            onClick={() => copyAll()}
            icon={
              <HiOutlineClipboardCopy
                style={{ transform: "scaleX(-1)" }}
                strokeWidth="1.5px"
                size={20}
              />
            }
          />
        </Tooltip>
      </Flex>
      <Flex flexDir="row" mx={6} alignItems="center">
        <Flex flexDir="column" gap={2} width="100%" pb={5}>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              fontSize="sm"
              color="gray.600"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              Debrief template
            </Text>
            {!summaryExists && templates && generateSummary && (
              <Box>
                <Tooltip label="Generates using all public interviews for the candidate">
                  <Button
                    fontSize="xs"
                    size="xs"
                    disabled={isGenerateDisabled}
                    onClick={() => {
                      setIsGenerateDisabled(true);
                      generateSummary(selectedTemplate);
                    }}
                  >
                    Generate Debrief
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Flex>
          <Select
            size="sm"
            borderRadius="md"
            onChange={(e) => {
              handleChangeTemplate(e.target.value);
            }}
            value={selectedTemplate}
            width="100%"
          >
            {templates?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

const reshapeNotes = (data: CandidateSummary): Topic[] => {
  const { notes } = data;
  const sortedNotes = [...notes].sort((a, b) => a.position - b.position);

  // Convert sorted notes into topics, subtopics, notes, and citations
  const topics: Topic[] = [];
  let currentTopic: Topic | null = null;
  let currentSubtopic: SubTopic | null = null;
  let currentNote: Note | null = null;
  sortedNotes.forEach((note) => {
    if (currentTopic === null || currentTopic.name !== note.topic) {
      currentTopic = {
        name: note.topic || "",
        subTopics: [],
      };
      topics.push(currentTopic);
    }
    if (currentSubtopic === null || currentSubtopic.name !== note.subtopic) {
      currentSubtopic = {
        name: note.subtopic || "",
        notes: [],
      };
      currentTopic.subTopics.push(currentSubtopic);
    }
    currentNote = {
      id: note.id,
      text: note.text,
      citations: note.citations.map((citation) => ({
        id: citation.id,
        callId: citation.callId,
        time: citation.time,
      })),
    };
    currentSubtopic.notes.push(currentNote);
  });

  return topics;
};

export default SummaryTabContent;
