import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Papa from "papaparse";
import React, { useState } from "react";
import { useFieldArray, useForm, UseFormRegister } from "react-hook-form";
import { BsPlusCircle } from "react-icons/bs";
import { MdDelete, MdEmail } from "react-icons/md";
import readXlsxFile from "read-excel-file";

import Alert from "../Alert";
import { useToast } from "../useToast";

interface FormUser {
  email?: string;
  userRoleId?: string;
  planUserRoleId?: string | null;
}

interface FormValues {
  users?: FormUser[];
}

interface Role {
  id: string;
  name: string;
  permissions: string[];
  formattedName?: string | null;
  formattedPermissions?: string | null;
}

interface InviteUsersButtonProps {
  inviteUsers: (
    users: {
      email: string;
      userRoleId: string;
      planUserRoleId?: string | null;
    }[]
  ) => Promise<void>;
  roles: Role[];
  includePlanRoleField?: boolean;
  planRoles: Role[];
  disabled?: boolean;
  maxInvites?: number;
}

const InviteUsersButton: React.FC<InviteUsersButtonProps> = ({
  inviteUsers,
  roles,
  includePlanRoleField = false,
  planRoles,
  disabled = false,
  maxInvites = 5,
}) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApolloError | null>(null);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      users: [
        {
          email: "",
          userRoleId: roles[0].id ?? "",
          planUserRoleId: planRoles[0].id ?? "",
        },
      ],
    },
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "users",
  });

  const submitInviteUser = handleSubmit(
    async (values: FormValues): Promise<void> => {
      const validUsers = values.users?.filter(
        (
          user
        ): user is {
          email: string;
          userRoleId: string;
          planUserRoleId?: string | null;
        } => !!user.email
      );
      if (validUsers?.length) {
        setError(null);
        setIsLoading(true);
        try {
          await inviteUsers(validUsers);
          setIsLoading(false);
          setIsOpen(false);
          reset();
        } catch (error) {
          setIsLoading(false);
          setError(error);
        }
      }
    }
  );

  const updateUsers = (
    users: { email: string; role: string; planRole: string }[]
  ): void => {
    const roleMap = roles.reduce((acc, role): { [key: string]: string } => {
      acc[role.name.toLowerCase()] = role.id;
      return acc;
    }, {} as { [key: string]: string });
    const planRoleMap = planRoles.reduce(
      (acc, role): { [key: string]: string } => {
        acc[role.name.toLowerCase()] = role.id;
        return acc;
      },
      {} as { [key: string]: string }
    );
    for (let i = 0; i < fields.length; i++) {
      remove(i);
    }
    users.forEach((user, idx) => {
      const roleId = roleMap[user.role.toLowerCase()];
      const planRoleId = planRoleMap[user.planRole.toLowerCase()];
      append({
        email: user.email,
        userRoleId: roleId,
        planUserRoleId: planRoleId,
      });
    });
  };

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e?.target?.files?.[0];
    if (!file) {
      return;
    }
    const filename = e.target.value;
    if (filename.endsWith(".csv")) {
      new Promise((resolve) => {
        Papa.parse(file, {
          worker: true,
          complete: (results: { data: string[][] }): void => {
            resolve(results.data);
          },
        });
      })
        .then((rows: unknown): void => {
          updateUsers(
            (rows as string[][])
              .slice(1)
              .map((row) => {
                return { email: row[0], role: row[1], planRole: row[2] };
              })
              .filter((row) => row.email)
          );
        })
        .catch(() => {
          toast({
            title: "Error",
            description: `Error reading file. Did you use the sample file?`,
            status: "error",
          });
        });
    } else if (filename.endsWith(".xls") || filename.endsWith(".xlsx")) {
      readXlsxFile(file)
        .then((rows) => {
          updateUsers(
            rows.slice(1).map((row) => {
              return {
                email: String(row[0]),
                role: String(row[1]),
                planRole: String(row[2]),
              };
            })
          );
        })
        .catch(() => {
          toast({
            title: "Error",
            description: `Error reading file. Did you use the sample file?`,
            status: "error",
          });
        });
    } else {
      toast({
        title: "Error",
        description: `Invalid file format. Must be a CSV or Excel file.`,
        status: "error",
      });
    }
  };

  const interviewRoleFieldName = includePlanRoleField
    ? "Interview Role"
    : "Role";

  const templateFileName = includePlanRoleField
    ? "BrightHireUserInviteTemplate.xlsx"
    : "UserInviteTemplate.xlsx";

  return (
    <>
      <Button
        data-testid="invite-users"
        size="sm"
        leftIcon={<MdEmail />}
        disabled={roles.length < 1 || disabled}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Invite users
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay>
          <ModalContent maxW={includePlanRoleField ? "650px" : undefined}>
            <ModalHeader>Invite Users</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form id="invite-users-form" onSubmit={submitInviteUser}>
                {error && <Alert status="error" description={error.message} />}
                <Box display="table">
                  <Box display="table-row" key="header" height="2.2rem">
                    <FormControl display="table-cell">
                      <FormLabel htmlFor="email">Email</FormLabel>
                    </FormControl>
                    <FormControl id="userRoleId" display="table-cell">
                      <FormLabel>{interviewRoleFieldName}</FormLabel>
                    </FormControl>
                    <FormControl
                      id="planUserRoleId"
                      display="table-cell"
                      hidden={!includePlanRoleField}
                    >
                      <FormLabel>Plan Role</FormLabel>
                    </FormControl>
                    <FormControl display="table-cell">
                      <FormLabel>Remove</FormLabel>
                    </FormControl>
                  </Box>
                  {fields.map((field, idx) => (
                    <UserRow
                      key={field.id}
                      idx={idx}
                      user={field}
                      roles={roles}
                      includePlanRoleField={includePlanRoleField}
                      planRoles={planRoles}
                      register={register}
                      remove={remove}
                      isDisabled={fields.length < 2}
                      emailError={errors?.users?.[idx]?.email?.message}
                    />
                  ))}
                </Box>
                <Button
                  variant="outline"
                  size="sm"
                  width="200px"
                  leftIcon={<BsPlusCircle />}
                  onClick={() => {
                    const users = getValues()?.users;
                    const userRoleId =
                      users && users.length > 0
                        ? users[users.length - 1].userRoleId
                        : "";
                    const planUserRoleId =
                      users && users.length > 0
                        ? users[users.length - 1].planUserRoleId
                        : "";
                    append({ email: "", userRoleId, planUserRoleId });
                  }}
                  isDisabled={fields.length === maxInvites}
                >
                  Add another
                </Button>
              </form>
            </ModalBody>
            <ModalFooter>
              <Box mr="auto" fontSize="xs">
                <Text mb={1}>
                  or upload a file{" "}
                  <Link href={`/static/files/${templateFileName}`} download>
                    like this
                  </Link>
                </Text>
                <input
                  type="file"
                  id="input"
                  placeholder="Upload"
                  onChange={onUpload}
                />
              </Box>
              <ButtonGroup>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  type="submit"
                  form="invite-users-form"
                  data-testid="invite"
                  isLoading={isLoading}
                >
                  Invite
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

const UserRow: React.FC<{
  user: FormUser;
  idx: number;
  roles: Role[];
  includePlanRoleField?: boolean;
  planRoles: Role[];
  emailError?: string;
  register: UseFormRegister<FormValues>;
  isDisabled: boolean;
  remove: (idx: number) => void;
}> = ({
  user,
  idx,
  roles,
  includePlanRoleField,
  planRoles,
  register,
  remove,
  emailError,
  isDisabled,
}) => {
  const role = roles.find((r) => user.userRoleId === r.id);
  const planRole = planRoles.find((r) => user.planUserRoleId === r.id);

  return (
    <Box display="table-row">
      <FormControl
        isRequired
        isInvalid={emailError !== undefined}
        display="table-cell"
        h="50px"
      >
        <Input
          data-testid={`email[${idx}]`}
          type="email"
          size="sm"
          width="200px"
          {...register(`users.${idx}.email`, {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          defaultValue={user.email}
        />
        {emailError !== undefined && (
          <FormErrorMessage my="1">{emailError}</FormErrorMessage>
        )}
      </FormControl>
      <Tooltip label={role?.formattedPermissions ?? undefined} openDelay={250}>
        <FormControl isRequired display="table-cell" pr={4}>
          <Select
            {...register(`users.${idx}.userRoleId`)}
            size="sm"
            data-testid={`userRoleId[${idx}]`}
            w="max-content"
            minW="112px"
          >
            {roles.map((r) => (
              <option key={r.id} value={r.id}>
                {r.formattedName}
              </option>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
      {includePlanRoleField && (
        <Tooltip
          label={planRole?.formattedPermissions ?? undefined}
          openDelay={250}
        >
          <FormControl display="table-cell" pr={4}>
            <Select
              {...register(`users.${idx}.planUserRoleId`)}
              size="sm"
              data-testid={`planUserRoleId[${idx}]`}
              w="max-content"
              minW="112px"
            >
              {planRoles.map((r) => (
                <option key={r.id} value={r.id}>
                  {r.formattedName}
                </option>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      )}
      <Box display="table-cell">
        <Button
          size="sm"
          variant="outline"
          leftIcon={<MdDelete />}
          isDisabled={isDisabled}
          onClick={() => {
            remove(idx);
          }}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};

export default InviteUsersButton;
