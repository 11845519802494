import React from "react";
import { useSearchParams } from "react-router-dom";

import { dateRange90DaysAgo } from "../../../../components/SelectDateRange/SelectDateRange";
import {
  useNumberSearchParam,
  useSearchParam,
  useSelectDateRangeParams,
} from "../../../../hooks/useSearchParam";
import { formatISODate } from "../../../../utils/datetime";
import { useAnalyticsSkillsReportLazyQuery } from "../../../graphql";
import AnalyticsSkillsReportResults from "./AnalyticsSkillsReportResults";
import AnalyticsSkillsReportBuilder from "./builder/AnalyticsSkillsReportBuilder";

const AnalyticsSkillsReport: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const [builderStep, setBuilderStep] = useNumberSearchParam("step", 0);
  const [positionId, setPositionId] = useSearchParam("positionId", undefined);
  const [candidateFilter, setCandidateFilter] = useSearchParam(
    "candidateFilter",
    "any"
  );
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    dateRange90DaysAgo()
  );

  const [generateSkillReport, { loading, data }] =
    useAnalyticsSkillsReportLazyQuery();

  const handleGenerateReport = (data: any): void => {
    generateSkillReport({
      variables: {
        positionId: data.positionId,
        dateRangeStart: formatISODate(data.dateRange.start),
        dateRangeEnd: formatISODate(data.dateRange.end),
        skills: data.skills.map(
          (skill: { name: string; description?: string }) => ({
            name: skill.name,
            description: skill.description,
          })
        ),
        conditionNumCandidateCalls: data.candidateFilter === "all" ? 3 : 1,
      },
    });
  };

  return builderStep === 2 ? (
    <AnalyticsSkillsReportResults
      loading={loading}
      data={data}
      startBuildReport={() => {
        setSearchParams({});
        setBuilderStep(0);
      }}
      dateRange={dateRange}
    />
  ) : (
    <AnalyticsSkillsReportBuilder
      onGenerateReport={handleGenerateReport}
      positionId={positionId}
      candidateFilter={candidateFilter}
      dateRange={dateRange}
      onPositionIdChange={setPositionId}
      onCandidateFilterChange={setCandidateFilter}
      onDateRangeChange={setDateRange}
      builderStep={builderStep || 0}
      setBuilderStep={setBuilderStep}
    />
  );
};

export default AnalyticsSkillsReport;
