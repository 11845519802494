import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  DndContext,
  DragOverlay,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  HiDotsVertical,
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineX,
} from "react-icons/hi";
import { MdFormatAlignLeft, MdFormatListBulleted } from "react-icons/md";

import {
  EmptyAIIcon,
  FormatLengthLong,
  FormatLengthMedium,
  FormatLengthShort,
} from "../../../../../../components";
import CopyOutlineIcon from "../../../../../../components/Icons/CopyOutlineIcon";
import { SmallSegmentedButtonGroup } from "../../../../../../components/SmallSegmentedButtonGroup";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CallAiSummaryProcessingStatus,
  useCallAiSummaryLazyQuery,
  useDeleteAiSummaryCustomTopicMutation,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../../graphql";
import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import AiNotesLoading from "../Notes/NotesQA/AiNotesLoading";
import AiNotesMessage from "../Notes/NotesQA/AiNotesMessage";
import { MouseSensor } from "./customDragDrop";
import { SummaryAddCustomTopic } from "./SummaryAddCustomTopic";
import { SummaryContent } from "./SummaryContent";
import SummarySection from "./SummarySection";
import SummaryUnavailable from "./SummaryUnavailable";
import TopicWizard from "./TopicWizard";
import {
  CallAiSummary,
  NoteFormat,
  NoteLength,
  NoteMap,
  SummaryCallNote,
  SummaryCustomTopic,
} from "./types";
import { copySummary } from "./utils";

enum SummaryLocalStorageKey {
  SelectedNotes = "SelectedNotes",
  NoteFormat = "NoteFormat",
  NoteLength = "NoteLength",
  IndividualNoteLength = "IndividualNoteLength",
}

type SelectedNote = {
  id: string;
  tag: string;
};

export const Summary: React.FC<{
  callId: string;
  transcriptAvailable: boolean;
  callDuration?: number | null;
  onClickTimestamp(t: number): void;
}> = ({ callId, transcriptAvailable, callDuration, onClickTimestamp }) => {
  const toast = useToast();
  const [addTopicRegenerate, setAddTopicRegenerate] = useState<boolean>(false);
  const [fetchSummary, aiSummary] = useCallAiSummaryLazyQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });
  const [updateSummaryFormat] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });
  const regenerate = useCallback(
    (
      customTopics: { title: string; questions: string[] }[],
      forceRegenerate = false
    ) => {
      updateSummaryFormat({
        variables: {
          callId,
          format: CallAiSummaryFormat.Writeup,
          targetSpeakerTags: [],
          customTopics,
          forceRegenerate,
        },
      }).then(() => {
        fetchSummary({
          variables: {
            callId,
            format: CallAiSummaryFormat.Writeup,
          },
          pollInterval: 5000,
          fetchPolicy: "network-only",
        });
      });
    },
    []
  );
  useEffect(() => {
    fetchSummary({
      variables: {
        callId,
        format: CallAiSummaryFormat.Writeup,
      },
      pollInterval: 5000,
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        if (!res.callAiSummary?.id) {
          regenerate([], false);
        }
      },
    });
  }, []);

  const aiSummaryStatus = aiSummary.data?.callAiSummary?.status;
  useEffect(() => {
    if (
      aiSummaryStatus &&
      [
        CallAiSummaryProcessingStatus.Completed,
        CallAiSummaryProcessingStatus.Failed,
      ].includes(aiSummaryStatus)
    ) {
      aiSummary.stopPolling();
    }
  }, [aiSummary.stopPolling, aiSummaryStatus]);

  const { data } = aiSummary;

  if (!transcriptAvailable) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon colorScheme="purple" mb="5" />
        <Text align="center" maxW="360px">
          Hold tight! The AI Summary is generated once the transcript for the
          interview is ready.
        </Text>
      </AiNotesMessage>
    );
  }

  if (
    !data ||
    aiSummary.loading ||
    !data.callAiSummary?.status ||
    ![
      CallAiSummaryProcessingStatus.Completed,
      CallAiSummaryProcessingStatus.Failed,
    ].includes(data.callAiSummary.status)
  ) {
    return (
      <AiNotesLoading
        loadingText={
          addTopicRegenerate
            ? "Regenerating, this will just take a minute"
            : "Generating, this will just take a minute"
        }
      />
    );
  }

  if (data.callAiSummary.status === CallAiSummaryProcessingStatus.Failed) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon mb="5" />
        <Text align="center" maxW="360px">
          Something went wrong generating your summary.
        </Text>
        <Button
          variant="solid"
          onClick={() => {
            regenerate(data?.callAiSummary?.customTopics ?? [], true);
          }}
          my={4}
        >
          Retry?
        </Button>
      </AiNotesMessage>
    );
  }

  // No subtopics were found because recording was too short
  let foundAnySubtopics = false;
  if (data.callAiSummary.headers) {
    for (let i = 0; i < data.callAiSummary.headers?.length; i++) {
      const topic = data.callAiSummary.headers[i];
      if (topic.notes && topic.notes.length > 0) {
        foundAnySubtopics = true;
        break;
      }
    }
  }
  if (!foundAnySubtopics) {
    const helperText =
      callDuration && callDuration > 120
        ? "We couldn't find any topics in your transcript."
        : "A longer recording is needed to generate an AI summary.";
    return <SummaryUnavailable helperText={helperText} />;
  }

  return (
    <SummaryEditor
      callAiSummary={data.callAiSummary}
      callId={callId}
      onClickTimestamp={onClickTimestamp}
      regenerate={regenerate}
      setAddTopicRegenerate={setAddTopicRegenerate}
    />
  );
};

const SummaryEditor: React.FC<{
  callAiSummary: Pick<CallAiSummary, "id" | "headers" | "customTopics">;
  callId: string;
  onClickTimestamp(t: number): void;
  regenerate: (
    customTopics: { title: string; questions: string[] }[],
    forceRegenerate?: boolean
  ) => void;
  setAddTopicRegenerate: (val: boolean) => void;
}> = ({
  callId,
  onClickTimestamp,
  callAiSummary,
  regenerate,
  setAddTopicRegenerate,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { delay: 150, tolerance: 100 },
    })
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [selectTopicsOpen, setSelectTopicsOpen] = useState(false);

  const [wizardIsShown, setWizardIsShown] = useState(false);

  const toast = useToast();

  const [selectedNotes, setSelectedNotes] = useState<string[]>([]);

  const [currentFormat, setCurrentFormat] = useState(NoteFormat.Bullets);
  const [individualLengths, setIndividualLengths] = useState<{
    [key: string]: NoteLength;
  }>({});
  const [currentLength, setCurrentLength] = useState(NoteLength.Long);
  const [hoverId, setHoverId] = useState<string | null>(null);

  const sendGAEvent = useSendGAEvent();

  const saveToLocalStorage = (
    key: SummaryLocalStorageKey,
    data?: string
  ): void => {
    if (!callAiSummary.id) return;
    const storageKey = `aiSummary-${key}-${callId}`;
    if (!data) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, data);
    }
  };

  const getFromLocalStorage = (key: SummaryLocalStorageKey): string | null => {
    if (!callAiSummary.id) return null;
    const storageKey = `aiSummary-${key}-${callId}`;
    return localStorage.getItem(storageKey);
  };

  const onSetLength = useCallback(
    (id: string, length: NoteLength) => {
      const newLengths: { [key: string]: NoteLength } = {};
      newLengths[id] = length;
      setIndividualLengths((prevLengths) => {
        const updatedLengths = { ...prevLengths, ...newLengths };
        saveToLocalStorage(
          SummaryLocalStorageKey.IndividualNoteLength,
          JSON.stringify(updatedLengths)
        );
        return updatedLengths;
      });
    },
    [individualLengths]
  );

  const handleFormatChange = (label: NoteFormat): void => {
    saveToLocalStorage(SummaryLocalStorageKey.NoteFormat, label);
    setCurrentFormat(label);
  };

  const handleLengthChange = (label: NoteLength): void => {
    saveToLocalStorage(SummaryLocalStorageKey.NoteLength, label);
    setCurrentLength(label);
    setIndividualLengths({});
    saveToLocalStorage(SummaryLocalStorageKey.IndividualNoteLength);
  };

  const sections = useMemo(
    () => callAiSummary?.headers ?? [],
    [callAiSummary?.headers]
  );

  const notesMap = useMemo(() => {
    const notes: NoteMap = {};
    sections.forEach((s) => {
      s.notes?.forEach((n) => {
        notes[n.id] = { note: n, sectionTitle: s.text };
      });
    });
    return notes;
  }, [sections]);

  // set up saved state
  useEffect(() => {
    // Selected Notes
    const savedWriteUpNotes = getFromLocalStorage(
      SummaryLocalStorageKey.SelectedNotes
    );
    if (savedWriteUpNotes) {
      const savedWriteUpNotesList: Array<SelectedNote> =
        JSON.parse(savedWriteUpNotes);
      if (savedWriteUpNotesList.length > 0) {
        // Same Summary IDs that were saved
        if (notesMap[savedWriteUpNotesList[0].id]) {
          setSelectedNotes(
            savedWriteUpNotesList.reduce((arr: string[], note) => {
              if (notesMap[note.id]) {
                arr.push(note.id);
              }
              return arr;
            }, [])
          );
        } else {
          // Different IDs, so map by tag name
          const tagMap: NoteMap = {};
          Object.values(notesMap).forEach((note) => {
            tagMap[note.note.tag || ""] = note;
          });
          const newNotes = savedWriteUpNotesList.reduce(
            (arr: string[], note) => {
              if (tagMap[note.tag]) {
                if (tagMap[note.tag]?.note?.text !== "") {
                  arr.push(tagMap[note.tag].note.id);
                }
              }
              return arr;
            },
            []
          );
          setSelectedNotes(newNotes);
        }
      } else {
        setWizardIsShown(true);
      }
    } else {
      setWizardIsShown(true);
    }
    // Format
    const savedWriteUpFormat = getFromLocalStorage(
      SummaryLocalStorageKey.NoteFormat
    );
    if (savedWriteUpFormat) {
      setCurrentFormat(
        NoteFormat[savedWriteUpFormat as keyof typeof NoteFormat]
      );
    }
    // Length
    const savedWriteUpLength = getFromLocalStorage(
      SummaryLocalStorageKey.NoteLength
    );
    if (savedWriteUpLength) {
      setCurrentLength(
        NoteLength[savedWriteUpLength as keyof typeof NoteLength]
      );
    }
    // Individual lengths
    const savedWriteUpIndividualLengths = getFromLocalStorage(
      SummaryLocalStorageKey.IndividualNoteLength
    );
    if (savedWriteUpIndividualLengths) {
      setIndividualLengths(JSON.parse(savedWriteUpIndividualLengths));
    }
  }, [callAiSummary, notesMap]);

  const customTopicMap = useMemo(() => {
    const topics = new Map<string, SummaryCustomTopic>();
    callAiSummary?.customTopics?.forEach((t) => {
      topics.set(t.title, t);
    });
    return topics;
  }, [callAiSummary?.customTopics]);

  const handleOnHover = (id: string): void => {
    setHoverId(id);
  };
  const handleOffHover = (id: string): void => {
    if (hoverId === id) setHoverId(null);
  };

  const handleSelection = (id: string): void => {
    if (selectedNotes.includes(id)) {
      setSelectedNotes((prevNotes) => prevNotes.filter((n) => n !== id));
    } else {
      const ns = new Set([...selectedNotes, id]);
      setSelectedNotes([...ns]);
    }
  };

  useEffect(() => {
    if (callId) {
      const notes = selectedNotes.reduce((arr: SelectedNote[], noteId) => {
        arr.push({ id: noteId, tag: notesMap[noteId]?.note.tag || "" });
        return arr;
      }, []);
      saveToLocalStorage(
        SummaryLocalStorageKey.SelectedNotes,
        JSON.stringify(notes)
      );
    }
  }, [selectedNotes]);

  const onCopySummary = (): void => {
    copySummary({
      callId,
      selectedNotes,
      notesMap,
      individualNoteLengths: individualLengths,
      defaultNoteLength: currentLength,
      noteFormat: currentFormat,
      includeTimestamps: true,
    });
    sendGAEvent("copy_ai_summary", "call_review", undefined, undefined, {
      callId,
    });
    toast({
      title: "Summary copied to clipboard",
      status: "success",
      position: "top",
    });
  };

  const debugEnabled = useFeatureFlag("ai_notes:debug");

  const {
    isOpen: addCustomTopicIsOpen,
    onOpen: showAddCustomTopic,
    onClose: closeAddCustomTopic,
  } = useDisclosure();
  const addCustomTopic = useCallback(
    (t: string, questions: string[]) => {
      const ts: { title: string; questions: string[] }[] =
        callAiSummary.customTopics?.map((ct) => {
          return { title: ct.title, questions: ct.questions };
        }) ?? [];
      ts.push({ title: t, questions });
      setAddTopicRegenerate(true);
      regenerate(ts);
    },
    [callAiSummary.customTopics]
  );

  const noteFormatButtons = [
    {
      label: NoteFormat.Paragraph,
      as: <Icon as={MdFormatAlignLeft} height="1.25em" width="1.25em" />,
    },
    {
      label: NoteFormat.Bullets,
      as: <Icon as={MdFormatListBulleted} height="1.25em" width="1.25em" />,
    },
  ];

  const noteLengthButtons = [
    { label: NoteLength.Long },
    { label: NoteLength.Med },
    { label: NoteLength.Short },
  ];

  const items = useMemo(() => {
    return selectedNotes.reduce((arr: SummaryCallNote[], id) => {
      if (notesMap[id]) {
        arr.push(notesMap[id].note);
      }
      return arr;
    }, []);
  }, [selectedNotes]);
  const activeItem = items.find((i) => i.id === activeId);

  if (wizardIsShown) {
    return (
      <TopicWizard
        sections={sections}
        selectedNoteIds={selectedNotes}
        onSelect={handleSelection}
        onComplete={() => {
          setWizardIsShown(false);
          sendGAEvent(
            "generate_ai_summary",
            "call_review",
            undefined,
            undefined,
            {
              callId,
            }
          );
          LogRocket.track("ai-notes-summary-generated");
        }}
      />
    );
  }

  return (
    <Box
      mt="3"
      borderRadius="8px"
      border="1px solid"
      borderColor="gray.200"
      color="gray.700"
    >
      <SummaryAddCustomTopic
        isOpen={addCustomTopicIsOpen}
        onClose={closeAddCustomTopic}
        addCustomTopic={addCustomTopic}
      />

      <Flex bg="gray.50" borderRadius="8px" mb="2" justify="end">
        {debugEnabled && (
          <Button
            variant="ghost"
            size="xs"
            onClick={() => {
              const ts: { title: string; questions: string[] }[] =
                callAiSummary.customTopics?.map((ct) => {
                  return { title: ct.title, questions: ct.questions };
                }) ?? [];
              regenerate(ts, true);
            }}
          >
            Regenerate
          </Button>
        )}
      </Flex>
      <Box alignItems="center" px="12px">
        <Text
          fontWeight="600"
          lineHeight="1.25rem"
          fontSize="0.875rem"
          color="gray.800"
          mb={1}
        >
          Selected Topics
        </Text>
        <Text color="gray.600" fontSize="0.729rem" mb="12px">
          Rearrange topics by drag-and-drop. Customize individual lengths and
          edit custom topics via the 3-dot menu. Click once more to remove a
          selected topic.
        </Text>
      </Box>

      <Box px="12px">
        <DndContext
          sensors={sensors}
          onDragEnd={({ over }) => {
            if (over) {
              const overIndex = items.findIndex((i) => {
                return i.id === over.id;
              });

              const activeIndex = activeId
                ? items.findIndex((i) => i.id === activeId)
                : -1;
              if (activeIndex !== overIndex) {
                const newIndex = overIndex;

                setSelectedNotes((items) =>
                  arrayMove(items, activeIndex, newIndex)
                );
              }
            }

            setActiveId(null);
          }}
          onDragCancel={() => {
            setActiveId(null);
          }}
          onDragStart={({ active }) => {
            setActiveId(active.id);
          }}
        >
          <SortableContext
            items={items} /* strategy={horizontalListSortingStrategy} */
          >
            {items.map((item, idx) => (
              <DraggableNote
                idx={idx}
                key={item.id}
                note={item}
                isSelected
                onSelect={() => {
                  handleSelection(item.id);
                }}
                onSetLength={onSetLength}
                customTopic={customTopicMap.get(item.tag || "")}
                addCustomTopic={addCustomTopic}
                onHover={handleOnHover}
                offHover={handleOffHover}
              />
            ))}
            <SeeMoreLess
              allShowing={selectTopicsOpen}
              onClick={() => {
                setSelectTopicsOpen(!selectTopicsOpen);
              }}
            />
          </SortableContext>
          <DragOverlay adjustScale={false}>
            {activeItem && activeId ? (
              <DraggableNote
                idx={selectedNotes.indexOf(activeId.toString())}
                note={activeItem}
                onSelect={() => {
                  handleSelection(activeItem.id);
                }}
                isSelected={selectedNotes.includes(activeId.toString())}
                customTopic={customTopicMap.get(activeItem.tag || "")}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSetLength={() => {}}
                addCustomTopic={addCustomTopic}
                onHover={handleOnHover}
                offHover={handleOffHover}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Box>
      <Box px="12px" mb={4} mt={4} display={selectTopicsOpen ? "" : "none"}>
        <Box
          px="16px"
          py="12px"
          borderRadius="17px"
          border="5px solid"
          borderColor="blue.300"
        >
          <Flex width="100%" justifyContent="space-between">
            <Text
              fontWeight="medium"
              lineHeight="1.25rem"
              fontSize="0.875rem"
              color="gray.800"
              marginBottom={3}
            >
              Select Topics
            </Text>
            <Box
              alignSelf="center"
              cursor="pointer"
              color="gray.300"
              _hover={{ color: "gray.200" }}
            >
              <HiOutlineX
                onClick={() => {
                  setSelectTopicsOpen(false);
                }}
              />
            </Box>
          </Flex>
          <Flex direction="column" gap="3">
            {sections.map((section) => (
              <SummarySection
                key={section.id}
                section={section}
                selectedNoteIds={selectedNotes}
                onSelect={handleSelection}
                onHover={handleOnHover}
                offHover={handleOffHover}
                onCreateCustomTopic={
                  section.text === "Custom Topics"
                    ? showAddCustomTopic
                    : undefined
                }
                customTopicMap={customTopicMap}
                addCustomTopic={addCustomTopic}
              />
            ))}
          </Flex>
        </Box>
      </Box>
      <Flex
        my="2"
        alignItems="center"
        gap="3"
        px="12px"
        py="8px"
        border="1px solid"
        borderColor="gray.200"
        borderLeft="none"
        borderRight="none"
      >
        <Flex flexDir="column">
          <Text fontSize="0.729375rem" color="gray.600" mb="4px">
            Length
          </Text>
          <SmallSegmentedButtonGroup
            buttons={noteLengthButtons}
            activeButton={currentLength}
            onChange={(label: NoteLength) => handleLengthChange(label)}
            buttonWidth="44px"
          />
        </Flex>
        <Flex flexDir="column">
          <Text fontSize="0.729375rem" color="gray.600" mb="4px">
            Format
          </Text>
          <SmallSegmentedButtonGroup
            buttons={noteFormatButtons}
            activeButton={currentFormat}
            onChange={(label: NoteFormat) => handleFormatChange(label)}
            buttonWidth="35px"
          />
        </Flex>
      </Flex>
      <Box fontSize="sm" px="12px">
        <Flex
          display="flex"
          paddingBottom="3px"
          flexDirection="row"
          alignItems="center"
          alignSelf="stretch"
          justifyContent="space-between"
        >
          <Text flexGrow="1" fontWeight="600" color="gray.800">
            Summary
          </Text>
          <Tooltip label="Copy summary" openDelay={300}>
            <IconButton
              aria-label="Copy summary"
              size="xs"
              variant="ghost"
              alignSelf="flex-end"
              icon={<CopyOutlineIcon width="16px" height="16px" />}
              onClick={onCopySummary}
            />
          </Tooltip>
        </Flex>
        <SummaryContent
          callId={callId}
          hoverId={hoverId ?? activeId?.toString() ?? null}
          notesMap={notesMap}
          selectedNotes={selectedNotes}
          noteFormat={currentFormat}
          noteLength={currentLength}
          onClickTimestamp={onClickTimestamp}
          individualLengths={individualLengths}
        />
      </Box>
    </Box>
  );
};

const DraggableNote: React.FC<{
  onSelect: () => void;
  note: SummaryCallNote;
  isSelected: boolean;
  idx: number;
  addCustomTopic: (t: string, questions: string[]) => void;
  customTopic?: SummaryCustomTopic;
  onSetLength: (id: string, length: NoteLength) => void;
  onHover: (id: string) => void;
  offHover: (id: string) => void;
}> = ({
  note,
  isSelected,
  onSelect,
  idx,
  customTopic,
  addCustomTopic,
  onHover,
  offHover,
  onSetLength,
}) => {
  const { attributes, listeners, setNodeRef, over, activeIndex } = useSortable({
    id: note.id,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteCustomTopic] = useDeleteAiSummaryCustomTopicMutation({});
  return (
    <Box
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      display="inline-block"
      position="relative"
      _after={
        over?.id === note.id && idx > activeIndex
          ? {
              content: '""',
              position: "absolute",
              backgroundColor: "#4c9ffe",
              height: "22px",
              width: "2px",
              marginLeft: "10px",
              marginTop: "-2px",
            }
          : undefined
      }
      _before={
        over?.id === note.id && idx < activeIndex
          ? {
              content: '""',
              position: "absolute",
              backgroundColor: "#4c9ffe",
              height: "22px",
              width: "2px",
              marginLeft: "-12px",
              marginTop: "-2px",
            }
          : undefined
      }
      key={`box-${note.id}`}
      padding="0.125rem 0.375rem 0.125rem 0.375rem"
      gap="0.125rem"
      bg={isSelected ? "blue.50" : "white"}
      border="1px solid"
      borderColor="blue.600"
      _hover={{
        bg: "blue.50",
      }}
      borderRadius="0.1875rem"
      cursor="pointer"
      fontSize="0.72938rem"
      textColor="blue.600"
      onMouseEnter={() => {
        onHover(note.id);
      }}
      onMouseLeave={() => {
        offHover(note.id);
      }}
      whiteSpace="nowrap"
      mr={2}
      my={1}
    >
      <SummaryAddCustomTopic
        isOpen={isOpen}
        onClose={onClose}
        addCustomTopic={addCustomTopic}
        title={customTopic?.title}
        questions={customTopic?.questions}
      />
      <Box display="inline-block" onClick={onSelect}>
        {idx + 1}. {note.tag ?? "?"}
      </Box>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          display="inline-block"
          aria-label="More options"
          size="xs"
          minW="14px"
          width="14px"
          minH="14px"
          height="14px"
          variant="unstyled"
          color="blue.600"
          mt="-2px"
          mr="-4px"
          fontSize="12px"
          icon={<HiDotsVertical color="blue.600" />}
          data-no-dnd
        />
        <Portal>
          <MenuList fontSize="xs" maxW="120px" minW="120px" data-no-dnd>
            {customTopic && (
              <Box
                borderBottom="1px solid"
                borderBottomColor="gray.200"
                mb="8px"
              >
                <MenuItem
                  fontWeight="400"
                  icon={<HiOutlinePencil fontSize="16px" />}
                  onClick={onOpen}
                  iconSpacing="8px"
                  color="gray.800"
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<HiOutlineTrash fontSize="16px" />}
                  fontWeight="400"
                  iconSpacing="8px"
                  color="red.600"
                  onClick={() => {
                    onSelect();
                    deleteCustomTopic({
                      variables: {
                        customTopicNoteId: note.id,
                      },
                    });
                  }}
                >
                  Delete
                </MenuItem>
              </Box>
            )}
            <Text color="gray.500" fontSize="2xs" fontWeight="600" ml="8px">
              TEXT LENGTH
            </Text>
            <MenuItem
              icon={<FormatLengthShort fontSize="16px" />}
              onClick={() => {
                onSetLength(note.id, NoteLength.Short);
              }}
              iconSpacing="8px"
            >
              Short
            </MenuItem>
            <MenuItem
              icon={<FormatLengthMedium fontSize="16px" />}
              onClick={() => {
                onSetLength(note.id, NoteLength.Med);
              }}
              iconSpacing="8px"
            >
              Medium
            </MenuItem>
            <MenuItem
              icon={<FormatLengthLong fontSize="16px" />}
              onClick={() => {
                onSetLength(note.id, NoteLength.Long);
              }}
              iconSpacing="8px"
            >
              Long
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

const SeeMoreLess: React.FC<{
  allShowing: boolean;
  onClick: () => void;
}> = ({ allShowing, onClick }) => {
  return (
    <Box
      key="box-add-topic"
      display="inline-block"
      padding="0.125rem 0.375rem 0.125rem 0.375rem"
      gap="0.125rem"
      bg="white"
      border="1px solid"
      borderColor="blue.600"
      _hover={{
        bg: "blue.50",
      }}
      borderRadius="0.1875rem"
      cursor="pointer"
      fontSize="0.72938rem"
      textColor="blue.600"
      mr={2}
      my={1}
      onClick={onClick}
    >
      <Flex display="flex" flexDir="row" justifyContent="center">
        {allShowing ? "See less topics" : "See all topics"}
        <Box justifySelf="flex-end" alignSelf="center" mr="-2px" ml="3px">
          {allShowing ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
        </Box>
      </Flex>
    </Box>
  );
};
