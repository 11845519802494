import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Kbd,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import React, {
  FocusEventHandler,
  KeyboardEvent,
  ReactElement,
  useState,
} from "react";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

import useSelectTheme from "../../hooks/useSelectTheme";
import { useTheme } from "..";

type MultiValueSelectProps<T> = StyleProps & {
  value?: T[];
  onAddValue?(value: string): void;
  onValueChange?(anyValue: T[]): void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  validator?(value: string): string | null;
  getOptionLabel?(value: T): string;
  getOptionValue?(value: T): string;
  addValueOnBlur?: boolean;
  autoFocus?: boolean;
  label?: string;
  labelFor?: string;
  helpTextComponent?: ReactElement;
  helpTextItem?: string;
  error?: string | null;
  placeholder?: string;
  selectStyles?: Partial<StylesConfig>;
};

const MultiValueSelect = <T,>({
  value,
  onAddValue: onAddValueProp,
  onValueChange,
  onFocus,
  onBlur,
  validator = (newValue) => newValue,
  getOptionLabel,
  getOptionValue,
  addValueOnBlur = false,
  autoFocus,
  label,
  labelFor,
  helpTextComponent,
  helpTextItem = "items",
  error,
  placeholder,
  selectStyles,
  ...styleProps
}: MultiValueSelectProps<T>): JSX.Element => {
  const { colors } = useTheme();
  const [theme, styles] = useSelectTheme({
    ...selectStyles,
    control: (provided, props) => {
      return {
        ...provided,
        ...selectStyles?.control?.(provided, props),
        borderColor: error ? colors.red[500] : undefined,
        boxShadow: "",
        "&:hover": {
          borderColor: error ? colors.red[500] : undefined,
        },
      };
    },
  });

  const [inputValue, setInputValue] = useState("");

  const onAddValue = (): void => {
    const newValue = validator(inputValue);
    if (newValue) {
      onAddValueProp?.(newValue);
      setInputValue("");
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        onAddValue();
        event.preventDefault();
    }
  };

  return (
    <FormControl isInvalid={!!error} {...styleProps}>
      {label && <FormLabel htmlFor={labelFor}>{label}</FormLabel>}

      <CreatableSelect
        theme={theme}
        styles={styles}
        components={{
          DropdownIndicator: null,
        }}
        isClearable
        isMulti
        autoFocus={autoFocus}
        inputValue={inputValue}
        menuIsOpen={false}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onKeyDown={handleKeyDown}
        onInputChange={(newValue, { action }) => {
          if (action === "input-blur" || action === "menu-close") return;
          setInputValue(newValue);
        }}
        onChange={(anyValue) => onValueChange?.(anyValue as T[])}
        onFocus={onFocus}
        onBlur={(e) => {
          onBlur?.(e);
          if (addValueOnBlur) {
            onAddValue();
          }
        }}
      />

      {error && <FormErrorMessage>{error}</FormErrorMessage>}

      {!error &&
        (helpTextComponent || (
          <Text fontSize="xs" pt="2">
            Use <Kbd>Enter</Kbd> or <Kbd>,</Kbd> to enter multiple{" "}
            {helpTextItem}
          </Text>
        ))}
    </FormControl>
  );
};

export default MultiValueSelect;
