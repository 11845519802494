import { Box, Button, Flex, Heading, useInterval } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";

import { Card, LoadingIndicator } from "../../../../components";
import { itemAnimation, listAnimation } from "../../../../components/Animation";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { InterviewMenuOptions } from "../../../components/InterviewsList";
import { useCurrentUserCallsQuery } from "../../../graphql";
import CompletedInterviewsListItem from "./CompletedInterviewsListItem";
import { CompletedEmptyState, CompletedErrorState } from "./EmptyState";

const PAGE_LIMIT = 10;
const REFRESH_DELAY = 1000 * 60 * 5;

const CompletedInterviews: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const onClickLink = (itemType: string): void =>
    sendGAEvent("Link to", "home_page", itemType, "Completed");

  const [date] = useState<string>(new Date().toISOString().substring(0, 19));

  const { loading, error, data, refetch } = useCurrentUserCallsQuery({
    variables: {
      start: null,
      end: date,
      positionId: null,
      pagination: {
        limit: PAGE_LIMIT,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const calls = data?.currentUser?.calls?.results || [];
  const pageInfo = data?.currentUser?.calls?.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMore = (): void => {
    refetch({
      pagination: {
        limit: calls.length + PAGE_LIMIT,
      },
    });
  };

  /**
   * Executes when it's time to refresh the contents of the list
   */
  const onRefresh = (): void => {
    refetch({
      pagination: {
        limit: Math.max(calls.length, PAGE_LIMIT),
      },
    });
  };

  // Execute onRefresh on a set timer
  useInterval(onRefresh, REFRESH_DELAY);

  return (
    <Box order={2} marginBottom={calls.length ? 12 : 20}>
      <Flex width="100%" justifyContent="space-between" mb="5">
        <Heading
          lineHeight="28px"
          size="md"
          fontWeight="600"
          data-tour-id="completed"
          data-testid="homepage-module-title-completed-interviews"
        >
          Completed interviews
        </Heading>
      </Flex>
      <Card
        data-testid="completed-interviews"
        pt={0}
        pb={0}
        px={0}
        variant="transparent"
        maxW="100%"
        overflowX="auto"
      >
        {!error && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={listAnimation}
          >
            {calls.map((call, i) => {
              return (
                <motion.div
                  variants={itemAnimation}
                  key={`completed-interviews-row-item-${i + 1}`}
                >
                  <CompletedInterviewsListItem
                    call={call}
                    key={call.id}
                    listPosition={i + 1}
                    menuOptions={[
                      InterviewMenuOptions.SHARE,
                      InterviewMenuOptions.DELETE,
                    ]}
                    onClickCandidate={() => onClickLink("Candidate")}
                    onClickPosition={() => onClickLink("Position")}
                    onClickInterview={() => onClickLink("Interview")}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
        {!loading && !error && calls.length > 0 && pageInfo.hasNextPage && (
          <Button
            variant="chalk"
            fontSize="sm"
            fontWeight={500}
            height="32px"
            onClick={onLoadMore}
          >
            Load more
          </Button>
        )}
        {loading && (
          <Flex width="100%" height="100%">
            <LoadingIndicator py={6} delay={0} />
          </Flex>
        )}
        {!loading && !error && calls.length === 0 && <CompletedEmptyState />}
        {!loading && error && <CompletedErrorState />}
      </Card>
    </Box>
  );
};

export default CompletedInterviews;
