import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

import { EmptyAIIcon } from "../../../components";

export const NoCandidateAlerts: React.FC = () => (
  <Flex
    direction="column"
    align="center"
    mx="4"
    bg="blackAlpha.50"
    borderRadius="md"
    pt="9"
    pb="12"
    px="5"
    textAlign="center"
  >
    <EmptyAIIcon width="40" height="auto" mb="5" />
    <Text color="gray.700" fontSize="md" fontWeight="semibold" mb="3">
      No candidate alerts yet
    </Text>
    <Text color="gray.600" fontSize="sm" fontWeight="normal">
      Any alerts we flag during interviews will appear here.{" "}
      <Link
        color="blue.600"
        fontSize="sm"
        fontWeight="normal"
        href="https://help.brighthire.ai/en/articles/9763163-candidate-alerts"
        target="_blank"
      >
        Learn more
      </Link>
      .
    </Text>
  </Flex>
);
