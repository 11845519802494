import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys
);

export default defineMultiStyleConfig({
  baseStyle: ({ colorScheme }) => ({
    control: {
      _checked: {
        bg: `${colorScheme}.600`,
        borderColor: `${colorScheme}.600`,
        _hover: {
          bg: `${colorScheme}.500`,
          borderColor: `${colorScheme}.500`,
        },
        _active: {
          bg: `${colorScheme}.700`,
          borderColor: `${colorScheme}.700`,
        },
      },
    },
    label: {
      color: "gray.900",
    },
  }),
  variants: {
    padded: ({ size }) => ({
      control: {
        m:
          size === "lg"
            ? "3.5"
            : size === "md"
            ? "2.5"
            : size === "sm"
            ? "2"
            : undefined,
      },
      label: {
        ml: 0,
      },
    }),
  },
  sizes: {
    lg: {
      control: {
        boxSize: "5",
      },
      label: {
        fontSize: "lg",
      },
    },
    md: {
      control: {
        boxSize: "5",
      },
      label: {
        fontSize: "md",
      },
    },
    sm: {
      control: {
        boxSize: "4",
      },
      label: {
        fontSize: "md",
      },
    },
  },
});
